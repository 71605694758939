import React, { Component, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link,
} from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Tabs,
  Tab,
  Modal as ModalForm,
} from "react-bootstrap";
import seru2 from "../../../assets/img/Group 895.png";
import empty from "../../../assets/img/empty.png";
import proyk from "../../../assets/img/Group 863.png";
import dompet from "../../../assets/img/dompet.png";
import waiting from "../../../assets/img/Gambar Waitting-01.svg";
import bali from "../../../assets/img/bali.png";
import { BASE_URL } from "../../../service/api";
import templateproposal from "../../../assets/Kisi-kisi Proposal.pptx";
import "../../../App.scss";
import { Form as FormBootstrap } from "react-bootstrap";
import $ from "jquery";
import { fetchApi } from "../../../service/api";
import axios from "axios";
import DetailDompet from "../../DetailDompet";
import {
  Button,
  Breadcrumb,
  TextArea,
  Pagination,
  Progress,
  Modal,
  Dropdown,
  Checkbox,
  Grid,
  Card,
  Header,
  Icon,
  Image,
  Menu,
  Segment,
  Sidebar,
  Input,
  Label,
  Message,
  List,
  Form,
} from "semantic-ui-react";
import swal from "sweetalert";
import { CameraFeed } from "../../../components/CameraFeed";

import TabInformasi from "./Informasi";
import TabPengajuan from "./Pengajuan";

export default class TabUserPengelola extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
      gambarProyek1: "",
      gambarProyek2: "",
      gambarProyek3: "",
      gambarProyek4: "",
      gambarProyek5: "",
      gambarProyek: [],
      kolom: [0, 1, 2, 3, 4, 5],
      nama_project: "",
      nilai_proyek: "",
      deskripsi: "",
      type_project: "",
      type_efek: "",
      paramBack: "",
      proposal: "",
      rab: "",
      visibleError: false,
      msgError: "",
      msgSuccess: "",
      visibleSuccess: false,
      dataProyekPengelola: [],
      dataCategory: [],
      itemsDompet: [],
      musyarokah: "Pilih File",
      wbu: "Pilih File",
      hideDiv: "close",
      hideDivPrykBrjln: "close",
      hideDivTermin2: "close",
      gambarTermin1: "Pilih File",
      hideDivPermintaanDoc: "close",
      dataDetailPermintaanDoc: [],
      hideDivStatusPryk: "close",
      hideUtama: "close",
      gambarPenggunaanDana: "Pilih File",
      gambarProposal: "Unggah Proposal",
      dataDetailPrykBrjln: [],
      hideDivTermin1: "close",
      hideDivPryktglBrakhir: "close",
      dataDetailStatusPryk: [],
      tglTransaksi_Termin1: "",
      dataDetailPryktglBrakhir: [],
      openTandaTangan: false,
      openTolakPryk: false,
      filepermintaanDoc: "Pilih File",
      contentNotifPermintaanDoc: "",
      dataHUjroh: [],
      dokumen_lengkap: "",
      closeOnEscape: false,
      begin: 0,
      end: 4,
      activePage: 1,
      dataProyekPengelolaR: [],
      dataDetailStatusPrykSelesai: [],
      hideDivStatusPrykSelesai: "close",
      closeOnDimmerClick: false,
      dataDetailPrykBgHsl: [],
      wbuFile: "",
      proyekSelesai: "belum",
      hideDivRealisasiPryk: "close",
      hslnilaiProyek: "",
      open: false,
      realisasihslpryk: "Pilih File",
      tglUplRealisasi: "",
      showMsgRealisasi: "hidden",
      msgRealisasi: "",
      openAgreePrivy: false,
      openTolakPrivy: false,
      openInputPrivy: false,
      hari: "",
      tanggal: "",
      myDays: [
        "Minggu",
        "Senin",
        "Selasa",
        "Rabu",
        "Kamis",
        "Jum&#39;at",
        "Sabtu",
      ],
      defaultTabs: "",

      showModalAgreement: false,
      agreementContent: "",
      showModalRegisterSigned: false,
      fileRegisterSigned: { foto_selfie: "", foto_ktp: "" },
      errorMessage: {},

      listPengajuan: [],
      listRequestDocument: [],
      listCampaign: [],
      listCampaignR: [],
    };
  }

  clearConsole() {
    if (window.console || window.console.firebug) {
      console.clear();
    }
  }

  getDompet() {
    axios
      .get(
        BASE_URL + "/customer/dompet",
        (axios.defaults.headers.common["Authorization"] = localStorage.token),
        (axios.defaults.headers.common["Accept"] = "application/json")
      )
      .then((resp) => {
        if (resp.data.data) {
          var date = new Date();
          var day = date.getDate();
          var month = date.getMonth();
          var yy = date.getYear();
          var year = yy < 1000 ? yy + 1900 : yy;
          var months = [
            "Januari",
            "Februari",
            "Maret",
            "April",
            "Mei",
            "Juni",
            "Juli",
            "Agustus",
            "September",
            "Oktober",
            "November",
            "Desember",
          ];
          var myDays = [
            "Minggu",
            "Senin",
            "Selasa",
            "Rabu",
            "Kamis",
            "Jum&#39;at",
            "Sabtu",
          ];
          var thisDay = date.getDay();
          thisDay = myDays[thisDay];
          var tgl = day + " " + months[month] + " " + year;

          this.setState({
            itemsDompet: resp.data.data,
            tanggal: tgl,
            hari: thisDay,
          });
        } else {
          // alert('Terjadi Kesalahan, Silahkan refresh browser');
          swal({
            text: "Terjadi Kesalahan, Silahkan refresh browser",
            icon: "error",
            button: "OK",
          });
          return false;
        }
      })
      .catch((resp) => {});
  }

  componentDidMount() {
    fetch(BASE_URL + "/frontend?status=1")
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result.response,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
          });
        }
      );

    this.getDompet();
    this.tabinformtabstatus();
    this.tabDataOptions();
  }

  onFileChange(e, param, termin) {
    var files = e.target.files;
    if (!files.length) {
      if (param == 90) {
        $("#fileBillPayTermin" + termin).html("Pilih File");
      } else {
        this.setState({
          gambarPenggunaanDana: "Pilih File",
          realisasihslpryk: "Pilih File",
        });
      }
    } else {
      return this.createImage(files[0], param, termin);
    }
  }

  createImage(file, param, termin) {
    var tmp = [];
    var reader = new FileReader();

    reader.onload = (e) => {
      if (param == 0) {
        this.setState({
          gambarProyek1: e.target.result,
        });
      } else if (param == 1) {
        this.setState({
          gambarProyek2: e.target.result,
        });
      } else if (param == 2) {
        this.setState({
          gambarProyek3: e.target.result,
        });
      } else if (param == 3) {
        this.setState({
          gambarProyek4: e.target.result,
        });
      } else if (param == 4) {
        this.setState({
          gambarProyek5: e.target.result,
        });
      } else if (param == 17) {
        this.setState({
          realisasihslpryk: file.name,
        });
      } else if (param == 87) {
        this.setState({
          gambarPenggunaanDana: file.name,
        });
        $(".uploadPenggunaanDana").removeAttr("disabled");
        $(".uploadPenggunaanDana").removeClass("disabled");
      } else if (param == 88) {
        this.setState({
          gambarTermin1: file.name,
        });
        $(".uploadTermin1").removeAttr("disabled");
        $(".uploadTermin1").removeClass("disabled");
      } else if (param == 89) {
        this.setState({
          gambarProposal: file.name,
        });
      } else if (param == 90) {
        $("#fileBillPayTermin" + termin).html(file.name.slice(0, 12));
      } else if (param == 99) {
        this.setState({
          filepermintaanDoc: file.name,
        });
      } else if (param == 81) {
        this.setState({
          musyarokah: file.name.slice(0, 7),
        });
      } else if (param == 82) {
        this.setState({
          wbu: file.name.slice(0, 7),
        });
      }
    };
    reader.readAsDataURL(file);
  }

  onFileDocumentChange(e, index) {
    var files = e.target.files;
    if (!files.length) {
      $("#textFileDoc-" + index).html("Pilih File");
      this.state.listRequestDocument[index].file = null;
    } else {
      $("#textFileDoc-" + index).html(files[0].name);
      var reader = new FileReader();
      reader.onload = (e) => {
        this.state.listRequestDocument[index].file = e.target.result;
      };
      reader.readAsDataURL(files[0]);
    }
  }

  handleChange = (e, { value }) =>
    this.setState({
      type_project: value,
    });

  handleChangeEfek = (e, { value }) =>
    this.setState({
      type_efek: value,
    });

  clickDetailStatusPryk(id_project, param) {
    $(".btnDetailStatusPryk" + id_project).addClass("loading");
    $(".btnDetailStatusPryk" + id_project).addClass("disabled");

    axios
      .get(
        BASE_URL + "/projectdetail",
        {
          params: {
            id_project: id_project,
            pblc: "prvt",
          },
        },
        (axios.defaults.headers.common["Authorization"] = localStorage.token),
        (axios.defaults.headers.common["Accept"] = "application/json")
      )
      .then((resp) => {
        if (param == "dataDetailStatusPryk") {
          this.setState({
            hideDivTermin1: "close",
          });
          $(".divDetailTermin1").removeAttr("style");

          this.setState({
            hideDivStatusPryk: "open",
            dataDetailStatusPryk: resp.data.data[0],
          });
        } else if (param == "dataDetailPryktglBrakhir") {
          $(".divDetailTermin1PrykBrakhir").removeAttr("style");
          this.setState({
            hideDivTermin1: "close",
            dataDetailStatusPryk: resp.data.data[0],
          });
        }

        $(".btnDetailStatusPryk" + id_project).removeClass("loading");
        $(".btnDetailStatusPryk" + id_project).removeClass("disabled");
      })
      .catch((resp) => {
        $(".btnDetailStatusPryk" + id_project).removeClass("loading");
        $(".btnDetailStatusPryk" + id_project).removeClass("disabled");
      });
  }

  clickDetailStatusPrykSelesai(id_project, param) {
    $(".btnDetailStatusPrykSelesai" + id_project).addClass("loading");
    $(".btnDetailStatusPrykSelesai" + id_project).addClass("disabled");

    axios
      .get(
        BASE_URL + "/projectdetail",
        {
          params: {
            id_project: id_project,
            pblc: "prvt",
          },
        },
        (axios.defaults.headers.common["Authorization"] = localStorage.token),
        (axios.defaults.headers.common["Accept"] = "application/json")
      )
      .then((resp) => {
        if (param == "dataDetailStatusPrykSelesai") {
          this.setState({
            hideDivTermin1: "close",
          });
          $(".divDetailTermin1").removeAttr("style");

          var akad_wbu = "";

          resp.data.data[0].proyek_data.map((a, i) => {
            if (a.type == "akad_wbu") {
              akad_wbu = a.nama_file;
            }
          });

          this.setState({
            hideDivStatusPrykSelesai: "open",
            dataDetailStatusPrykSelesai: resp.data.data[0],
            wbuFile: akad_wbu,
          });
          $(".divStatusPryk").attr("style", "display:none");
        }

        $(".btnDetailStatusPrykSelesai" + id_project).removeClass("loading");
        $(".btnDetailStatusPrykSelesai" + id_project).removeClass("disabled");
      })
      .catch((resp) => {
        $(".btnDetailStatusPrykSelesai" + id_project).removeClass("loading");
        $(".btnDetailStatusPrykSelesai" + id_project).removeClass("disabled");
      });
  }

  clickDetailPermintaanDoc(id_project, param) {
    $(".btnDetailPermintaanDoc" + id_project).addClass("loading");
    $(".btnDetailPermintaanDoc" + id_project).addClass("disabled");
    var userinfo = JSON.parse(this.props.userinfo);

    axios
      .get(
        BASE_URL + "/projectdetail",
        {
          params: {
            id_project: id_project,
            pblc: "prvt",
          },
        },
        (axios.defaults.headers.common["Authorization"] = localStorage.token),
        (axios.defaults.headers.common["Accept"] = "application/json")
      )
      .then((resp) => {
        if (param == "dataDetailPermintaanDoc") {
          this.setState({
            hideDivPermintaanDoc: "open",
            dataDetailPermintaanDoc: resp.data.data[0],
          });
          $(".divStatusPryk").attr("style", "display:none");
        }
        // this.getNotifPermintaanDoc(userinfo.user_id, id_project)
        this.getListRequestDocument(userinfo.user_id, id_project);

        $(".btnDetailPermintaanDoc" + id_project).removeClass("loading");
        $(".btnDetailPermintaanDoc" + id_project).removeClass("disabled");
      })
      .catch((resp) => {
        $(".btnDetailPermintaanDoc" + id_project).removeClass("loading");
        $(".btnDetailPermintaanDoc" + id_project).removeClass("disabled");
      });
  }

  getListRequestDocument(id_user, id_project) {
    axios
      .get(
        BASE_URL + "/customer/pengelola/document/request_list",
        {
          params: {
            id_user: id_user,
            id_project: id_project,
          },
        },
        (axios.defaults.headers.common["Authorization"] = localStorage.token),
        (axios.defaults.headers.common["Accept"] = "application/json")
      )
      .then((resp) => {
        // swal({ text: resp.data.message, icon: 'success' })
        this.setState({
          listRequestDocument: resp.data.data,
        });
      })
      .catch((error) => {
        try {
          let errorStatusCode = error.response.status;

          if (errorStatusCode === 401) {
            setTimeout(() => {
              window.location.href = "/Signin";
              localStorage.removeItem("token");
              localStorage.clear();

              this.props.dispatch({
                type: "AUTH_USER_LOGOUT",
              });
            }, 500);
          } else {
            let errorBody = error.response.data;
            swal({ text: errorBody.message, icon: "error" });
          }
        } catch (error) {
          swal({ text: "Error tidak terdefinisi!", icon: "error" });
        }
      });
  }

  getListCampaign() {
    var userinfo = JSON.parse(this.props.userinfo);

    axios
      .get(
        BASE_URL + "/customer/pengelola/proyekpengelola",
        {
          params: {
            id_pengelola: userinfo.id,
            order_by: "created_at",
            limit: 10,
            campaign: true,
          },
        },
        (axios.defaults.headers.common["Authorization"] = localStorage.token),
        (axios.defaults.headers.common["Accept"] = "application/json")
      )
      .then((resp) => {
        this.setState({
          listCampaignR: resp.data.data,
          listCampaign: resp.data.data.slice(this.state.begin, this.state.end),
        });
      })
      .catch((error) => {});
  }

  getNotifPermintaanDoc(id_user, id_project) {
    axios
      .get(
        BASE_URL + "/customer/notifpermintaandoc",
        {
          params: {
            id_user: id_user,
            id_project: id_project,
          },
        },
        (axios.defaults.headers.common["Authorization"] = localStorage.token),
        (axios.defaults.headers.common["Accept"] = "application/json")
      )
      .then((resp) => {
        var tmpContent = "<ul>";
        resp.data.data.map((a, i) => {
          tmpContent += "<li>" + a.content + "</li>";
        });
        tmpContent += "</ul>";
        var content = tmpContent;
        $("#contentNotifPermintaanDoc").html(content);
        this.setState({
          contentNotifPermintaanDoc: resp.data.data,
          dokumen_lengkap: resp.data.data[0].dokumen_lengkap,
        });
      })
      .catch((resp) => {});
  }

  getHUjroh(id_project) {
    var userinfo = JSON.parse(this.props.userinfo);

    axios
      .get(
        BASE_URL + "/customer/pengelola/gethujroh",
        {
          params: {
            id_project: id_project,
            id_pengelola: userinfo.id,
          },
        },
        (axios.defaults.headers.common["Authorization"] = localStorage.token),
        (axios.defaults.headers.common["Accept"] = "application/json")
      )
      .then((resp) => {
        if (resp.data.data.length > 0) {
          this.setState({
            dataHUjroh: resp.data.data[0],
          });
        } else {
          var data = {
            status_upload: "Belum",
            nominal: 0,
          };
          this.setState({
            dataHUjroh: data,
          });
        }
      })
      .catch((resp) => {});
  }

  formatTanggal(date) {
    var date = new Date(date);
    var day = date.getDate();
    var month = date.getMonth();
    var yy = date.getYear();
    var year = yy < 1000 ? yy + 1900 : yy;
    var months = [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];
    var myDays = [
      "Minggu",
      "Senin",
      "Selasa",
      "Rabu",
      "Kamis",
      "Jum&#39;at",
      "Sabtu",
    ];
    var thisDay = date.getDay();
    thisDay = myDays[thisDay];
    var tgl = day + " " + months[month] + " " + year;
    return tgl;
  }

  formatRupiah(bilangan) {
    var rev = parseInt(bilangan);
    return "Rp. " + rev.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  timeBetweenDates(startDate, toDate) {
    var dateEntered = new Date(toDate);
    var now = new Date();
    var difference = dateEntered.getTime() - now.getTime();

    var seconds = Math.floor(difference / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);

    var bataspendaftaran = "Berakhir";
    hours %= 24;
    minutes %= 60;
    seconds %= 60;
    if (days == 0) {
      bataspendaftaran = "Sampai Hari Ini";
      return bataspendaftaran;
    } else if (days < 0) {
      bataspendaftaran = "Berakhir";
      return bataspendaftaran;
    } else {
      bataspendaftaran = days + " Hari Lagi";
      return bataspendaftaran;
    }
  }

  clickTermin1(id_project, param) {
    $(".btnPencairanTermin1" + id_project).addClass("loading");
    $(".btnPencairanTermin1" + id_project).addClass("disabled");

    this.getHUjroh(id_project);

    axios
      .get(
        BASE_URL + "/projectdetail",
        {
          params: {
            id_project: id_project,
            pblc: "prvt",
          },
        },
        (axios.defaults.headers.common["Authorization"] = localStorage.token),
        (axios.defaults.headers.common["Accept"] = "application/json")
      )
      .then((resp) => {
        this.setState({
          hideDivTermin1: "open",
          dataDetailStatusPryk: resp.data.data[0],
          paramBack: param,
        });
        $(".divDetailTermin1").attr("style", "display:none");
        $(".divDetailTermin1PrykBrakhir").attr("style", "display:none");
        $(".btnPencairanTermin1" + id_project).removeClass("loading");
        $(".btnPencairanTermin1" + id_project).removeClass("disabled");
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      })
      .catch((resp) => {
        $(".btnPencairanTermin1" + id_project).removeClass("loading");
        $(".btnPencairanTermin1" + id_project).removeClass("disabled");
      });
  }

  clickDetailPryktglBrakhir(id_project, param) {
    $(".btnDetailPryktglBrakhir" + id_project).addClass("loading");
    $(".btnDetailPryktglBrakhir" + id_project).addClass("disabled");

    axios
      .get(
        BASE_URL + "/projectdetail",
        {
          params: {
            id_project: id_project,
            pblc: "prvt",
          },
        },
        (axios.defaults.headers.common["Authorization"] = localStorage.token),
        (axios.defaults.headers.common["Accept"] = "application/json")
      )
      .then((resp) => {
        this.setState({
          hideDivPryktglBrakhir: "open",
          dataDetailPryktglBrakhir: resp.data.data[0],
        });
        $(".divStatusPryk").attr("style", "display:none");
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        $(".btnDetailPryktglBrakhir" + id_project).removeClass("loading");
        $(".btnDetailPryktglBrakhir" + id_project).removeClass("disabled");
      })
      .catch((resp) => {
        $(".btnDetailPryktglBrakhir" + id_project).removeClass("loading");
        $(".btnDetailPryktglBrakhir" + id_project).removeClass("disabled");
      });
  }

  clickDetailPrykBrjln(id_project, param) {
    $(".btnDetailPrykBrjln" + id_project).addClass("loading");
    $(".btnDetailPrykBrjln" + id_project).addClass("disabled");

    axios
      .get(
        BASE_URL + "/projectdetail",
        {
          params: {
            id_project: id_project,
            pblc: "prvt",
          },
        },
        (axios.defaults.headers.common["Authorization"] = localStorage.token),
        (axios.defaults.headers.common["Accept"] = "application/json")
      )
      .then((resp) => {
        if (param == "dataDetailPrykBrjln") {
          this.setState({
            hideDivTermin2: "close",
            hideDivRealisasiPryk: "close",
          });
          $(".divDetailTermin2").removeAttr("style");

          this.setState({
            hideDivPrykBrjln: "open",
            dataDetailPrykBrjln: resp.data.data[0],
          });
          $(".divStatusPryk").attr("style", "display:none");
        }
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        $(".btnDetailPrykBrjln" + id_project).removeClass("loading");
        $(".btnDetailPrykBrjln" + id_project).removeClass("disabled");
      })
      .catch((resp) => {
        $(".btnDetailPrykBrjln" + id_project).removeClass("loading");
        $(".btnDetailPrykBrjln" + id_project).removeClass("disabled");
      });
  }

  clickDetailPrykBgHsl(id_project, param) {
    $(".btnDetailPrykBgHsl" + id_project).addClass("loading");
    $(".btnDetailPrykBgHsl" + id_project).addClass("disabled");

    axios
      .get(
        BASE_URL + "/projectdetail",
        {
          params: {
            id_project: id_project,
            pblc: "prvt",
          },
        },
        (axios.defaults.headers.common["Authorization"] = localStorage.token),
        (axios.defaults.headers.common["Accept"] = "application/json")
      )
      .then((resp) => {
        if (param == "dataDetailPrykBgHsl") {
          this.setState({
            hideDivTermin2: "close",
          });
          $(".divDetailTermin2").removeAttr("style");

          this.setState({
            hideDivPrykBrjln: "open",
            dataDetailPrykBgHsl: resp.data.data[0],
          });
          $(".divStatusPryk").attr("style", "display:none");
        }
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        $(".btnDetailPrykBgHsl" + id_project).removeClass("loading");
        $(".btnDetailPrykBgHsl" + id_project).removeClass("disabled");
      })
      .catch((resp) => {
        $(".btnDetailPrykBgHsl" + id_project).removeClass("loading");
        $(".btnDetailPrykBgHsl" + id_project).removeClass("disabled");
      });
  }

  clickTermin2(id_project, param) {
    $(".btnPencairanTermin2" + id_project).addClass("loading");
    $(".btnPencairanTermin2" + id_project).addClass("disabled");

    axios
      .get(
        BASE_URL + "/projectdetail",
        {
          params: {
            id_project: id_project,
            pblc: "prvt",
          },
        },
        (axios.defaults.headers.common["Authorization"] = localStorage.token),
        (axios.defaults.headers.common["Accept"] = "application/json")
      )
      .then((resp) => {
        this.setState({
          hideDivTermin2: "open",
          dataDetailPrykBrjln: resp.data.data[0],
          paramBack: param,
        });
        $(".divDetailTermin2").attr("style", "display:none");

        $(".btnPencairanTermin2" + id_project).removeClass("loading");
        $(".btnPencairanTermin2" + id_project).removeClass("disabled");
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      })
      .catch((resp) => {
        $(".btnPencairanTermin2" + id_project).removeClass("loading");
        $(".btnPencairanTermin2" + id_project).removeClass("disabled");
      });
  }

  clickRealisasiHslPryk(id_project, param) {
    $(".btnPencairanRealisasiHslPryk" + id_project).addClass("loading");
    $(".btnPencairanRealisasiHslPryk" + id_project).addClass("disabled");

    axios
      .get(
        BASE_URL + "/projectdetail",
        {
          params: {
            id_project: id_project,
            pblc: "prvt",
          },
        },
        (axios.defaults.headers.common["Authorization"] = localStorage.token),
        (axios.defaults.headers.common["Accept"] = "application/json")
      )
      .then((resp) => {
        this.setState({
          hideDivRealisasiPryk: "open",
          dataDetailPrykBrjln: resp.data.data[0],
          paramBack: param,
        });
        $(".divDetailTermin2").attr("style", "display:none");

        $(".btnPencairanRealisasiHslPryk" + id_project).removeClass("loading");
        $(".btnPencairanRealisasiHslPryk" + id_project).removeClass("disabled");
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        $(".divDetBayarUjroh").attr("style", "height:auto");

        if (resp.data.data[0].project_result != 0) {
          this.setState({
            hslnilaiProyek: resp.data.data[0].project_result.nominal,
            tglUplRealisasi: resp.data.data[0].project_result.created_at,
            realisasihslpryk:
              resp.data.data[0].project_result.id_project + ".zip",
          });
          $("#embedpollfilerealisasihslpryk").attr("disabled", "disabled");
          $(".embedpollfilerealisasihslpryk").attr("style", "opacity:0.5");
          $(".uploadRealisasi").addClass("disabled");
          $(".inputhslnilaiproyek").addClass("disabled");
        }

        if (resp.data.data[0].bill_payment != 0) {
          for (var i = 0; i < resp.data.data[0].bill_payment.length; i++) {
            $(
              "#fileBillPayTermin" + resp.data.data[0].bill_payment[i].termin
            ).html("Pilih File");

            if (
              resp.data.data[0].bill_payment[i].status_upload === "Belum" &&
              resp.data.data[0].solved_pay_hasil_pryk !=
                resp.data.data[0].bill_payment[i].termin
            ) {
              $(
                ".tglBillPaymentTermin" +
                  resp.data.data[0].bill_payment[i].termin
              ).addClass("disabled");
              $(
                ".fileBillPayTermin" + resp.data.data[0].bill_payment[i].termin
              ).attr("disabled", "disabled");
              $(
                "#fileBillPayTermin" + resp.data.data[0].bill_payment[i].termin
              ).addClass("disabled");
              $(
                ".uploadBillTermin" + resp.data.data[0].bill_payment[i].termin
              ).addClass("disabled");
              $(
                ".dueDateTermin" + resp.data.data[0].bill_payment[i].termin
              ).attr("style", "opacity:0.5");
              $(
                ".nominalBillPayment" + resp.data.data[0].bill_payment[i].termin
              ).addClass("disabled");
            } else if (
              resp.data.data[0].bill_payment[i].status_upload === "Sudah"
            ) {
              $(
                ".tglBillPaymentTermin" +
                  resp.data.data[0].bill_payment[i].termin
              ).addClass("disabled");
              $(
                ".fileBillPayTermin" + resp.data.data[0].bill_payment[i].termin
              ).attr("disabled", "disabled");
              $(
                "#fileBillPayTermin" + resp.data.data[0].bill_payment[i].termin
              ).addClass("disabled");
              $(
                ".uploadBillTermin" + resp.data.data[0].bill_payment[i].termin
              ).addClass("disabled");
              $(
                ".dueDateTermin" + resp.data.data[0].bill_payment[i].termin
              ).attr("style", "opacity:0.5");
              $(
                ".nominalBillPayment" + resp.data.data[0].bill_payment[i].termin
              ).addClass("disabled");
              var now = new Date(resp.data.data[0].bill_payment[i].trx_date);
              var day = ("0" + now.getDate()).slice(-2);
              var month = ("0" + (now.getMonth() + 1)).slice(-2);
              var today = now.getFullYear() + "-" + month + "-" + day;
              $(
                ".tglBillPaymentTermin" +
                  resp.data.data[0].bill_payment[i].termin +
                  ">input"
              ).val(today);

              if (resp.data.data[0].bill_payment[i].status_konfirmasi != 0) {
                $(
                  ".divTerminHslPryk" + resp.data.data[0].bill_payment[i].termin
                ).html(resp.data.data[0].bill_payment[i].status_konfirmasi);
                $(
                  ".divTerminHslPryk" + resp.data.data[0].bill_payment[i].termin
                ).attr("style", "opacity:0.5;padding-top:23px");
                $(
                  ".jdlTerminHslPryk" + resp.data.data[0].bill_payment[i].termin
                ).html("&nbsp;");
              }
            }
          }

          for (var i = 0; i < resp.data.data[0].bill_payment.length; i++) {
            if (resp.data.data[0].solved_pay_hasil_pryk == 0) {
              $(
                ".tglBillPaymentTermin" +
                  resp.data.data[0].bill_payment[i].termin
              ).removeClass("disabled");
              $(
                ".fileBillPayTermin" + resp.data.data[0].bill_payment[i].termin
              ).removeAttr("disabled");
              $(
                "#fileBillPayTermin" + resp.data.data[0].bill_payment[i].termin
              ).removeClass("disabled");
              $(
                ".uploadBillTermin" + resp.data.data[0].bill_payment[i].termin
              ).removeClass("disabled");
              $(
                ".dueDateTermin" + resp.data.data[0].bill_payment[i].termin
              ).removeAttr("style");
              $(
                ".nominalBillPayment" + resp.data.data[0].bill_payment[i].termin
              ).removeClass("disabled");
              break;
            }
          }
        }
      })
      .catch((resp) => {
        $(".btnPencairanRealisasiHslPryk" + id_project).removeClass("loading");
        $(".btnPencairanRealisasiHslPryk" + id_project).removeClass("disabled");
      });
  }

  PaginationClick = (e, { activePage }) => {
    if (activePage == 1) {
      this.setState({ begin: 0, end: 4 });
    } else if (activePage == 2) {
      this.setState({ begin: 4, end: 8 });
    } else if (activePage == 3) {
      this.setState({ begin: 8, end: 12 });
    } else if (activePage == 4) {
      this.setState({ begin: 12, end: 16 });
    } else if (activePage == 5) {
      this.setState({ begin: 16, end: 20 });
    } else if (activePage == 6) {
      this.setState({ begin: 20, end: 24 });
    } else if (activePage == 7) {
      this.setState({ begin: 24, end: 28 });
    } else if (activePage == 8) {
      this.setState({ begin: 28, end: 32 });
    } else if (activePage == 9) {
      this.setState({ begin: 32, end: 36 });
    } else if (activePage == 10) {
      this.setState({ begin: 36, end: 40 });
    } else if (activePage == 11) {
      this.setState({ begin: 40, end: 44 });
    } else if (activePage == 12) {
      this.setState({ begin: 44, end: 48 });
    } else if (activePage == 13) {
      this.setState({ begin: 48, end: 52 });
    } else if (activePage == 14) {
      this.setState({ begin: 52, end: 56 });
    } else if (activePage == 15) {
      this.setState({ begin: 56, end: 60 });
    } else if (activePage == 16) {
      this.setState({ begin: 60, end: 64 });
    } else if (activePage == 17) {
      this.setState({ begin: 64, end: 68 });
    } else if (activePage == 18) {
      this.setState({ begin: 68, end: 72 });
    } else if (activePage == 19) {
      this.setState({ begin: 72, end: 76 });
    } else if (activePage == 20) {
      this.setState({ begin: 76, end: 80 });
    }

    this.setState({ activePage });
    this.tabinformtabstatus();
  };

  tabinformtabstatus() {
    var userinfo = JSON.parse(this.props.userinfo);

    axios
      .get(
        BASE_URL + "/customer/pengelola/proyekpengelola",
        {
          params: {
            id_pengelola: userinfo.id,
            order_by: "created_at",
            limit: 10,
          },
        },
        (axios.defaults.headers.common["Authorization"] = localStorage.token),
        (axios.defaults.headers.common["Accept"] = "application/json")
      )
      .then((resp) => {
        this.setState({
          dataProyekPengelolaR: resp.data.data,
          dataProyekPengelola: resp.data.data.slice(
            this.state.begin,
            this.state.end
          ),
        });
      })
      .catch((resp) => {});
  }

  tabDataOptions() {
    // project_category and etc..\

    axios
      .get(
        BASE_URL + "/customer/pengelola/project_category",
        {},
        (axios.defaults.headers.common["Authorization"] = localStorage.token),
        (axios.defaults.headers.common["Accept"] = "application/json")
      )
      .then((resp) => {
        this.setState({
          dataCategory: resp.data.data,
        });
      })
      .catch((resp) => {});
  }

  priceHslPryk = () =>
    this.state.isFocus
      ? this.state.hslnilaiProyek.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      : this.state.hslnilaiProyek;

  price = () =>
    this.state.isFocus
      ? this.state.nilai_proyek.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      : this.state.nilai_proyek;

  handleBlur = (e) => this.setState({ isFocus: false });
  handleFocus = (e) => this.setState({ isFocus: true });
  handleTextChangePrice = (e) =>
    this.setState({ nilai_proyek: e.target.value.replace(/,/g, "") });
  handleTextChangeHslPryk = (e) =>
    this.setState({ hslnilaiProyek: e.target.value.replace(/,/g, "") });

  getListPengajuan() {
    var userinfo = JSON.parse(this.props.userinfo);
    axios
      .get(
        BASE_URL + "/customer/pengelola/pengajuan",
        {
          params: {
            id_pengelola: userinfo.id,
            // order_by: 'created_at',
            limit: 10,
          },
        },
        (axios.defaults.headers.common["Authorization"] = localStorage.token),
        (axios.defaults.headers.common["Accept"] = "application/json")
      )
      .then((resp) => {
        this.setState({ listPengajuan: resp.data.data });

        // if (resp.data.data) {
        //     var date = new Date();
        //     var day = date.getDate();
        //     var month = date.getMonth();
        //     var yy = date.getYear();
        //     var year = (yy < 1000) ? yy + 1900 : yy;
        //     var months = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
        //     var myDays = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jum&#39;at', 'Sabtu'];
        //     var thisDay = date.getDay();
        //     thisDay = myDays[thisDay];
        //     var tgl = day + ' ' + months[month] + ' ' + year

        //     this.setState({
        //         itemsDompet: resp.data.data,
        //         tanggal: tgl,
        //         hari: thisDay
        //     })

        // } else {
        //     swal({ text: 'Terjadi Kesalahan, Silahkan refresh browser', icon: 'error', button: 'OK' })
        //     return false;
        // }
      })
      .catch((error) => {});
  }

  handleSelectTab = (key) => {
    console.log("TAB_SELECTION : " + key);

    if (key === "daftarpengajuan") {
      this.getListPengajuan();
    }
    if (key === "transaksi") {
      // this.getDataTransaction()
    }
    if (key == "bisniscampaign") {
      this.getListCampaign();
    }
  };

  render() {
    this.clearConsole();

    var app = this;

    const showMdl = (e) => {
      this.setState({ open: true, size: "mini" });
    };

    const openMdlTolakPrivy = (e) => {
      this.setState({
        size: "mini",
        open: false,
        openAgreePrivy: false,
        openTolakPrivy: true,
      });
    };

    const closeMdl = (e) => {
      this.setState({ open: false });
    };

    const openMdlInputPrivy = (e) => {
      this.setState({ size: "mini", open: false, openInputPrivy: true });
    };

    const closeInputPrivy = (e) => {
      this.setState({ openInputPrivy: false });
    };

    const openMdlPrivy = (e) => {
      this.setState({ size: "mini", open: false, openAgreePrivy: true });
    };

    const closeAgreePrivy = (e) => {
      this.setState({ openAgreePrivy: false });
    };

    const clickbackProyekBrjln = (event) => {
      this.setState({
        hideDivStatusPryk: "close",
        hideDivStatusPrykSelesai: "close",
        hideDivPrykBrjln: "close",
        hideDivPryktglBrakhir: "close",
        hideDivTermin1: "close",
        hideDivTermin2: "close",
        hideDivPermintaanDoc: "close",
        hideDivRealisasiPryk: "close",
      });
      $(".divStatusPryk").removeAttr("style");
    };

    const closeTolakPryk = (e) => {
      this.setState({ openTolakPryk: false });
    };

    const clickHideTermin1 = (event) =>
      this.setState({
        hideDivTermin1: "close",
      });

    const openMdlTolakPryk = (e) => {
      this.setState({ size: "mini", openTolakPryk: true });
    };

    const openMdlTTD = (e) => {
      this.setState({ size: "mini", openTandaTangan: true });
    };

    const closeMdlTTD = (e) => {
      this.setState({ openTandaTangan: false });
    };

    const userinfo = JSON.parse(this.props.userinfo);
    const verified = this.props.verified;
    const verified_advance = this.props.verified_advance;
    const dataIsComplete = this.props.dataIsComplete;
    const agreement = this.props.agreement;

    const itemsDompet = this.state.itemsDompet;

    const {
      error,
      isLoaded,
      items,
      hideDivStatusPryk,
      hideDivTermin1,
      hideDivPermintaanDoc,
      openTandaTangan,
      size,
      open,
      closeOnEscape,
      closeOnDimmerClick,
      hideDivPrykBrjln,
      hideDivPryktglBrakhir,
      dataDetailPrykBrjln,
      hslnilaiProyek,
      openTolakPryk,
      hideDivTermin2,
      gambarPenggunaanDana,
      gambarTermin1,
      filepermintaanDoc,
      gambarProposal,
      dataDetailStatusPryk,
      dataDetailPermintaanDoc,
      tglTransaksi_Termin1,
      dataDetailPryktglBrakhir,
      paramBack,
      hideDivRealisasiPryk,
      openInputPrivy,
      openAgreePrivy,
      hari,
      tanggal,
      contentNotifPermintaanDoc,
      dataHUjroh,
      musyarokah,
      wbu,
      dokumen_lengkap,
      dataDetailStatusPrykSelesai,
      hideDivStatusPrykSelesai,
      dataDetailPrykBgHsl,
      wbuFile,
      proyekSelesai,
      realisasihslpryk,
      tglUplRealisasi,
      showMsgRealisasi,
      msgRealisasi,
    } = this.state;

    const form = {
      id_pengelola: userinfo.id,
      user_id: userinfo.user_id,
      nama_project: this.state.nama_project,
      deskripsi: this.state.deskripsi,
      modal: this.state.nilai_proyek,
      proposal: this.state.proposal,
      rab: this.state.rab,
      gunadana: "",
      buktitrf: "",
      paramsTermin: "",
      type_project: this.state.type_project,
      type_efek: this.state.type_efek,
    };

    if (userinfo.dokumen) {
      userinfo.dokumen.map((answer, i) => {
        if (answer.type == "foto_ktp") {
          this.state.basefotoKtp = answer.nama_file;
        } else if (answer.type == "selfie_ktp") {
          this.state.baseselfieKtp = answer.nama_file;
        } else if (answer.type == "npwp") {
          this.state.basenpwp = answer.nama_file;
        } else if (answer.type == "npwp_perusahaan") {
          this.state.basenpwpperusahaan = answer.nama_file;
        } else if (answer.type == "akta") {
          this.state.baseakta = answer.nama_file;
        } else if (answer.type == "nib") {
          this.state.basenib = answer.nama_file;
        } else if (answer.type == "sk_kemenkumham") {
          this.state.basesk_kemenkumham = answer.nama_file;
        } else if (answer.type == "ktp_pengurus") {
          this.state.basektp_pengurus = answer.nama_file;
        } else if (answer.type == "buku_tabungan") {
          this.state.basebuku_tabungan = answer.nama_file;
        }
      });
    }

    const options = [
      { key: "property", text: "Property", value: 0 },
      { key: "projectfinancing", text: "Project Financing", value: 1 },
      { key: "umkm", text: "UMKM", value: 2 },
    ];

    const optionsEfek = [
      { key: "ebu", text: "Efek Bersifat Utang (EBU)", value: "EBU" },
      { key: "ebe", text: "Efek Bersifat Ekuitas (EBE)", value: "EBE" },
    ];

    const handleTextChange = (event) => {
      this.setState({
        [event.target.name]: event.target.value,
      });
    };

    const handleDismiss = (event) =>
      this.setState({
        visibleError: false,
        visibleSuccess: false,
      });

    const clickDetImg = function (nama_file, caption) {
      if (nama_file) {
        var modal = document.getElementById("myModal");
        var modalImg = document.getElementById("img01");
        var captionText = document.getElementById("caption");

        modal.style.display = "block";
        modalImg.src = nama_file;
        captionText.innerHTML = caption;
      }
    };

    const closeDetImg = function () {
      var modal = document.getElementById("myModal");
      modal.style.display = "none";
    };

    window.onclick = function (event) {
      var modal = document.getElementById("myModal");
      if (event.target == modal) {
        modal.style.display = "none";
      }
    };

    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        // reader.onloadend = function () {
        //     // alert(reader.result);
        //     var base64result = reader.result.substr(reader.result.indexOf(',') + 1);
        //     resolve(base64result);
        // };
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });

    const handleCloseModalRegisterSigned = () => {
      this.setState({ showModalRegisterSigned: false });
    };

    const handleShowModalAgreement = async () => {
      // this.setState({showModalAgreement: true})

      let config = {
        headers: {
          Authorization: localStorage.token,
          "content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Methods": "GET",
        },
        maxContentLength: 52428890,
        timeout: 10000000,
      };

      axios
        .get(BASE_URL + "/customer/pengelola/agreement/document", config)
        .then((resp) => {
          if (resp.data.success == false) {
            swal({ text: resp.data.body.message, icon: "error" });
          } else {
            /** STATUS CODE RESPONSE 200 */
            /** status: OK | code: USER_EXISTS_VERIFIED | message: Pengguna telah terdaftar dan Aktif. */
            /** status: OK | code: USER_DO_NOT_EXISTS | message: Pengguna belum terdaftar. */
            /** status: OK | code: USER_EXISTS_UNVERIFIED | message: Pengguna sudah terdaftar tapi belum verifikasi email. */
            /** status: OK | code: USER_EXISTS_CERTIVICATE_EXPIRED | message: Pengguna sudah terdaftar tapi sertifikat sudah kadaluwarsa. */
            /** END */

            if (resp.data.body.code == "USER_EXISTS_VERIFIED") {
              // console.log(resp.data.body.data)
              this.setState({ agreementContent: resp.data.body.data });
              this.setState({ showModalAgreement: true });
            } else if (resp.data.body.code == "USER_DO_NOT_EXISTS") {
              // swal({ text: 'Mohon untuk melakukan konfirmasi ulang Foto KTP dan Foto Selfie..', icon: 'warning' })
              this.setState({
                showModalRegisterSigned: true,
              });
            } else {
              swal({ text: resp.data.body.message, icon: "warning" });
            }
          }
        })
        .catch((error) => {
          try {
            let errorStatusCode = error.response.status;

            if (errorStatusCode === 401) {
              setTimeout(() => {
                window.location.href = "/Signin";
                localStorage.removeItem("token");
                localStorage.clear();

                this.props.dispatch({
                  type: "AUTH_USER_LOGOUT",
                });
              }, 500);
            } else {
              let errorBody = error.response.data.body;
              swal({ text: errorBody.message, icon: "error" });
            }
          } catch (error) {
            swal({ text: "Error tidak terdefinisi!", icon: "error" });
          }
        });
    };
    const handleCloseModalAgreement = () => {
      this.setState({ showModalAgreement: false });
    };

    const handleDirectSign = async () => {
      let config = {
        headers: {
          Authorization: localStorage.token,
          "content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Methods": "POST",
        },
        maxContentLength: 52428890,
        timeout: 10000000,
      };

      axios
        .post(BASE_URL + "/customer/pengelola/agreement/signed", config)
        .then((resp) => {
          if (resp.data.success == false) {
            swal({ text: resp.data.body.message, icon: "error" });
          } else {
            let signUrl = resp.data.body.data;

            // window.open(signUrl, "Popup","toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30")
            // window.open(signUrl, '_blank')

            //Direct portal tekenaja, jangan lupa buka
            //window.location.href = signUrl
            //this.setState({ showModalAgreement: false })
            //End Direct

            //Buat Demo, jangan lupa hapus
            swal({
              text: resp.data.body.message,
              icon: "success",
              closeOnClickOutside: false,
            }).then((value) => {
              window.location.href = signUrl;
              this.setState({ showModalAgreement: false });
            });
            //End Demo
          }
        })
        .catch((error) => {
          try {
            let errorStatusCode = error.response.status;

            if (errorStatusCode === 401) {
              setTimeout(() => {
                window.location.href = "/Signin";
                localStorage.removeItem("token");
                localStorage.clear();

                this.props.dispatch({
                  type: "AUTH_USER_LOGOUT",
                });
              }, 500);
            } else {
              let errorBody = error.response.data.body;
              // swal({ text: errorBody.message, icon: 'error' })
              var htmlFormat = document.createElement("div");
              htmlFormat.innerHTML = errorBody.message;
              swal({ content: htmlFormat, icon: "error" });
            }
          } catch (error) {
            swal({ text: "Error tidak terdefinisi!", icon: "error" });
          }
        });
    };

    const handleFileChange = (event) => {
      this.state.fileRegisterSigned.foto_ktp = event.target.value;
      this.setState({ fileRegisterSigned: this.state.fileRegisterSigned });

      var index = event.target.name;
      this.state.errorMessage[index] = "";

      this.setState({ errorMessage: this.state.errorMessage });
    };

    const uploadImage = async (files) => {
      if (files != "repeat") {
        //   setIsCaptureCamera(true)
        this.state.fileRegisterSigned.foto_selfie = files;
        this.setState({ fileRegisterSigned: this.state.fileRegisterSigned });
      } else {
        //   setIsCaptureCamera(false)
        this.state.fileRegisterSigned.foto_selfie = "";
        this.setState({ fileRegisterSigned: this.state.fileRegisterSigned });
      }

      this.state.errorMessage["foto_selfie"] = "";
      this.setState({ errorMessage: this.state.errorMessage });

      // Connect to a seaweedfs instance
    };

    const handleSubmitRegisterSigned = async (event) => {
      event.preventDefault();

      let errors = {};
      let isValid = true;

      let file = this.state.fileRegisterSigned;

      if (file.foto_selfie == "") {
        errors["foto_selfie"] = "Silahkan ambil foto selfie!";
        isValid = false;
      } else {
        if (file.foto_selfie.size > 1024000) {
          errors["foto_selfie"] = "Maksimal file upload 1 MB!";
          isValid = false;
        }
      }

      if (file.foto_ktp == "") {
        errors["foto_ktp"] = "Silahkan pilih foto KTP!";
        isValid = false;
      } else {
        var formField = document.querySelector(
          'form[name="formRegisterSigned"]'
        );
        var fileFoto = formField.querySelector('input[name="foto_ktp"]')
          .files[0];
        if (fileFoto.size > 1024000) {
          errors["foto_ktp"] = "Maksimal file upload 1 MB!";
          isValid = false;
        } else {
          file.foto_ktp = fileFoto;
        }
      }

      this.setState({ errorMessage: errors });

      if (isValid) {
        postData(file);
      } else {
        swal({
          text: "Periksa kembali data yang anda masukkan!",
          icon: "error",
        });
      }
    };

    const postData = async (values) => {
      // window.addEventListener("beforeunload", beforeUnloadListener);

      // console.log("POSTDATA : " + JSON.stringify(values))
      // return

      var formData = new FormData();
      formData.append(
        "foto_selfie",
        values.foto_selfie,
        "foto_selfie" + ".png"
      );
      formData.append("foto_ktp", values.foto_ktp, "foto_ktp" + ".png");

      var contentData = formData;

      let config = {
        headers: {
          Authorization: localStorage.token,
          "content-type": "multipart/form-data",
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Methods": "POST",
        },
        maxContentLength: 52428890,
        timeout: 10000000,
      };

      // if (post_to == 'document') { setLoading(true) }
      // if (post_to == 'document_upload') (setLoadingModalUpload(true))

      axios
        .post(
          BASE_URL + "/customer/pengelola/agreement/register",
          contentData,
          config
        )
        .then((resp) => {
          // window.removeEventListener("beforeunload", beforeUnloadListener);

          swal({ text: resp.data.body.message, icon: "success" });
          // console.log(JSON.stringify(resp.data.body))
        })
        .catch((error) => {
          // window.removeEventListener("beforeunload", beforeUnloadListener);

          try {
            let errorStatusCode = error.response.status;

            if (errorStatusCode === 401) {
              setTimeout(() => {
                window.location.href = "/Signin";
                localStorage.removeItem("token");
                localStorage.clear();

                this.props.dispatch({
                  type: "AUTH_USER_LOGOUT",
                });
              }, 500);
            } else {
              let errorBody = error.response.data.body;
              // swal({ text: errorBody.message, icon: 'error' })

              var htmlFormat = document.createElement("div");
              htmlFormat.innerHTML = errorBody.message;
              swal({ content: htmlFormat, icon: "error" });
            }
          } catch (error) {
            swal({ text: "Error tidak terdefinisi!", icon: "error" });
          }
        });
    };

    const clickdetailDompet = (event) =>
      this.setState({
        hideDiv: "open",
      });

    const closedetailDompet = (event) =>
      this.setState({
        hideDiv: "close",
      });

    const submitDokProyek = async (values) => {
      values.id_project = dataDetailStatusPryk.id_project;
      values.id_pengelola = userinfo.id;

      this.setState({
        gambarTermin1: "Pilih File",
        gambarPenggunaanDana: "Pilih File",
      });

      if (
        values.paramsTermin === "Termin1" ||
        values.paramsTermin === "UplWbu"
      ) {
        values.created_at = tglTransaksi_Termin1;
        values.id_ujroh = dataHUjroh.id_ujroh;
      } else if (
        values.paramsTermin === "Termin" + values.sisa_termin ||
        values.paramsTermin === "UplRealisasi" ||
        values.paramsTermin === "HasilProyekTermin" + values.sisa_termin
      ) {
        values.id_project = dataDetailPrykBrjln.id_project;
      }

      let config = {
        headers: {
          Authorization: localStorage.token,
          "content-type": "application/json",
          //   'content-type': 'multipart/form-data',
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Methods": "POST",
          //   'Access-Control-Allow-Headers' : 'Content-Type, Content-Length, Accept-Encoding',
        },
        maxContentLength: 52428890,
        timeout: 10000000,
        // mode: 'no-cors',
      };

      axios
        .post(BASE_URL + "/customer/pengelola/upldokumenproyek", values, config)
        .then((resp) => {
          $(".uploadPenggunaanDana").removeClass("loading");
          $(".uploadPenggunaanDana").removeClass("disabled");
          $(".uploadTermin1").removeClass("loading");
          $(".uploadTermin1").removeClass("disabled");
          $(".uploadMusyarokah").removeClass("loading");
          $(".uploadMusyarokah").removeClass("disabled");
          $(".uploadBillTermin" + values.sisa_termin).removeClass("loading");
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;

          if (resp.data.success == false) {
            // alert(resp.data.body.message)
            swal({ text: resp.data.body.message, icon: "error" });
          } else {
            if (
              values.paramsTermin === "UplRealisasi" ||
              values.paramsTermin === "HasilProyekTermin" + values.sisa_termin
            ) {
              this.setState({
                showMsgRealisasi: "",
                msgRealisasi: resp.data.body.message,
              });
              setTimeout(() => {
                this.setState({
                  showMsgRealisasi: "hidden",
                });
                clickbackProyekBrjln();
              }, 5000);
            } else {
              // alert(resp.data.body.message)
              swal({ text: resp.data.body.message, icon: "success" });
              setTimeout(() => {
                clickbackProyekBrjln();
              }, 1000);
            }
          }
        })
        .catch((resp) => {
          $(".uploadPenggunaanDana").removeClass("loading");
          $(".uploadPenggunaanDana").removeClass("disabled");
          $(".uploadTermin1").removeClass("loading");
          $(".uploadTermin1").removeClass("disabled");
          $(".uploadMusyarokah").removeClass("loading");
          $(".uploadMusyarokah").removeClass("disabled");
          $(".uploadBillTermin" + values.sisa_termin).removeClass("loading");
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;

          this.setState({
            msgError: "File Upload terlalu besar",
          });
        });
    };

    const submitPermintaanDoc = async (values) => {
      values.id_project = dataDetailPermintaanDoc.id_project;
      values.paramsTermin = "dokumen_lengkap";
      values.user_id = userinfo.user_id;

      this.setState({
        filepermintaanDoc: "Pilih File",
      });

      let config = {
        headers: {
          Authorization: localStorage.token,
          "content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Methods": "POST",
        },
        maxContentLength: 52428890,
        timeout: 10000000,
      };

      // axios.post(BASE_URL + "/customer/pengelola/upldokumenproyek", values, config)
      axios
        .post(
          BASE_URL + "/customer/pengelola/document/upload_document_bisnis",
          values,
          config
        )
        .then((resp) => {
          $(".uploadpermintaanDoc").removeClass("loading");
          $(".uploadpermintaanDoc").removeClass("disabled");

          if (resp.data.success == false) {
            swal({ text: resp.data.message, icon: "error", button: "OK" });
          } else {
            swal({ text: resp.data.message, icon: "success", button: "OK" });
            // setTimeout(() => {
            //     clickbackProyekBrjln();
            //     this.tabinformtabstatus();
            // }, 1000)
          }
        })
        .catch((error) => {
          $(".uploadpermintaanDoc").removeClass("loading");
          $(".uploadpermintaanDoc").removeClass("disabled");

          try {
            let errorStatusCode = error.response.status;

            if (errorStatusCode === 401) {
              setTimeout(() => {
                window.location.href = "/Signin";
                localStorage.removeItem("token");
                localStorage.clear();

                this.props.dispatch({
                  type: "AUTH_USER_LOGOUT",
                });
              }, 500);
            } else {
              let errorBody = error.response.data;
              swal({ text: errorBody.message, icon: "error" });
            }
          } catch (error) {
            swal({ text: "Error tidak terdefinisi!", icon: "error" });
          }
        });
    };

    async function handleUplMusyarakah() {
      var formField = document.forms[1];
      var musyFileNew = formField.querySelector('input[name="musyarokah"]')
        .files[0];

      if (musyFileNew) {
        var sizemusyFileNew = formField.querySelector(
          'input[name="musyarokah"]'
        ).files[0].size;
        var typemusyFileNew = formField.querySelector(
          'input[name="musyarokah"]'
        ).files[0].type;

        if (
          typemusyFileNew != "application/x-zip-compressed" &&
          typemusyFileNew != "application/zip"
        ) {
          // alert('File Musyarakah harus berupa Format .zip')
          swal({
            text: "File Musyarakah harus berupa Format .zip",
            icon: "error",
            button: "OK",
          });
          return false;
        }
      } else {
        // alert("Harap pilih file yang akan di upload terlebih dahulu...");
        swal({
          text: "Harap pilih file yang akan di upload terlebih dahulu",
          icon: "error",
          button: "OK",
        });
        return false;
      }

      if (musyFileNew) {
        form.filemusyarakah = await toBase64(musyFileNew);
        $(".uploadMusyarokah").addClass("loading");
        $(".uploadMusyarokah").addClass("disabled");
        form.paramsTermin = "UplMusyarakah";
        submitDokProyek(form);
      }
    }

    async function handleUplBillPayment(termin, bill_id) {
      var formField = document.forms[1];

      var trx_date = formField.querySelector(
        'input[name="tglBillPaymentTermin' + termin + '"]'
      ).value;
      var file_name = formField.querySelector(
        'input[name="fileBillPayTermin' + termin + '"]'
      ).files[0];

      if (!trx_date) {
        // alert('Tanggal Transaksi Wajib Diisi.. !!')
        swal({
          text: "Tanggal Transaksi wajib diisi",
          icon: "error",
          button: "OK",
        });
        return false;
      }

      if (file_name) {
        var sizefile_name = formField.querySelector(
          'input[name="fileBillPayTermin' + termin + '"]'
        ).files[0].size;
        var typefile_name = formField.querySelector(
          'input[name="fileBillPayTermin' + termin + '"]'
        ).files[0].type;

        if (
          (typefile_name !== "image/jpeg" &&
            typefile_name !== "image/png" &&
            typefile_name !== "image/gif") ||
          sizefile_name > 5120000
        ) {
          // alert('File Bukti Transfer berformat JPG/JPEG/PNG/GIF dan ukuran Max 5MB')
          swal({
            text: "File Bukti Transfer berformat JPG/JPEG/PNG/GIF dan ukuran Max 5MB",
            icon: "error",
            button: "OK",
          });
          return false;
        }
      } else {
        // alert("Harap pilih file bukti transfer terlebih dahulu...");
        swal({
          text: "Harap pilih file bukti transfer terlebih dahulu",
          icon: "error",
          button: "OK",
        });
        return false;
      }

      if (file_name) {
        form.buktitrf = await toBase64(file_name);
        form.trx_date = trx_date;
        $(".uploadBillTermin" + termin).addClass("loading");
        $(".uploadBillTermin" + termin).addClass("disabled");
        form.paramsTermin = "HasilProyekTermin" + termin;
        form.sisa_termin = termin;
        form.bill_id = bill_id;
        submitDokProyek(form);
      }
    }

    async function handleUplSubmit() {
      var formField = document.forms[1];

      var pdfgunadana = formField.querySelector('input[name="pdfgunadana"]')
        .files[0];

      if (pdfgunadana) {
        var sizepdfgunadana = formField.querySelector(
          'input[name="pdfgunadana"]'
        ).files[0].size;
        var typepdfgunadana = formField.querySelector(
          'input[name="pdfgunadana"]'
        ).files[0].type;

        if (
          typepdfgunadana !== "application/pdf" ||
          sizepdfgunadana > 5120000
        ) {
          // alert('File Dokumen Penggunaan Dana harus berformat PDF dan ukuran Max 5MB')
          swal({
            text: "File Dokumen Penggunaan Dana harus berformat PDF dan ukuran Max 5MB",
            icon: "error",
            button: "OK",
          });
          $(".uploadPenggunaanDana").removeClass("loading");
          $(".uploadPenggunaanDana").removeClass("disabled");
          return false;
        }
      } else {
        // alert("Harap pilih dokumen yang akan di upload terlebih dahulu...");
        swal({
          text: "Harap pilih dokumen yang akan di upload terlebih dahulu",
          icon: "error",
          button: "OK",
        });
        return false;
      }

      if (pdfgunadana) {
        form.gunadana = await toBase64(pdfgunadana);
        $(".uploadPenggunaanDana").addClass("loading");
        $(".uploadPenggunaanDana").addClass("disabled");
        form.paramsTermin = "Termin" + dataDetailPrykBrjln.sisa_termin;
        form.sisa_termin = dataDetailPrykBrjln.sisa_termin;
        submitDokProyek(form);
      }
    }

    async function handleUplRealisasi() {
      var formField = document.forms[1];

      var realisasiFileNew = formField.querySelector(
        'input[name="realisasihslpryk"]'
      ).files[0];

      if (realisasiFileNew) {
        var sizerealisasiFileNew = formField.querySelector(
          'input[name="realisasihslpryk"]'
        ).files[0].size;
        var typerealisasiFileNew = formField.querySelector(
          'input[name="realisasihslpryk"]'
        ).files[0].type;

        if (
          typerealisasiFileNew !== "application/x-zip-compressed" &&
          typerealisasiFileNew !== "application/zip"
        ) {
          // alert('File Realisasi hasil proyek harus berformat Zip')
          swal({
            text: "File Realisasi hasil proyek harus berformat Zip",
            icon: "error",
            button: "OK",
          });
          return false;
        }
      } else {
        // alert("Harap pilih file yang akan di upload terlebih dahulu...");
        swal({
          text: "Harap pilih file yang akan di upload terlebih dahulu",
          icon: "error",
          button: "OK",
        });
        return false;
      }

      if (hslnilaiProyek === "") {
        // alert('Hasil Nilai Proyek Wajib Diisi... !!');
        swal({
          text: "Hasil Nilai Proyek wajib diisi",
          icon: "error",
          button: "OK",
        });
        return false;
      }

      if (realisasiFileNew) {
        form.realisasi = await toBase64(realisasiFileNew);
        $(".uploadRealisasi").addClass("loading");
        $(".uploadRealisasi").addClass("disabled");
        form.paramsTermin = "UplRealisasi";
        form.hslnilaiProyek = hslnilaiProyek;
        submitDokProyek(form);
      }
    }

    async function handleUplWbuFile() {
      var formField = document.forms[1];

      var wbuFileNew = formField.querySelector('input[name="wbu"]').files[0];

      if (wbuFileNew) {
        var sizewbuFileNew =
          formField.querySelector('input[name="wbu"]').files[0].size;
        var typewbuFileNew =
          formField.querySelector('input[name="wbu"]').files[0].type;

        if (typewbuFileNew != "application/pdf" || sizewbuFileNew > 5120000) {
          // alert('File Wbu harus berformat PDF dan ukuran Max 5MB')
          swal({
            text: "File Wbu harus berformat PDF dan ukuran Max 5MB",
            icon: "error",
            button: "OK",
          });
          return false;
        }
      } else {
        // alert("Harap pilih file yang akan di upload terlebih dahulu...");
        swal({
          text: "Harap pilih file yang akan di upload terlebih dahulu",
          icon: "error",
          button: "OK",
        });
        return false;
      }

      if (wbuFileNew) {
        form.wbu = await toBase64(wbuFileNew);
        $(".uploadWbu").addClass("loading");
        $(".uploadWbu").addClass("disabled");
        form.paramsTermin = "UplWbu";
        submitDokProyek(form);
      }
    }

    async function handleUplSubmitTermin1() {
      var formField = document.forms[1];

      var buktitrf = formField.querySelector('input[name="gambartermin1"]')
        .files[0];

      if (buktitrf) {
        var sizebuktitrf = formField.querySelector(
          'input[name="gambartermin1"]'
        ).files[0].size;
        var typebuktitrf = formField.querySelector(
          'input[name="gambartermin1"]'
        ).files[0].type;

        if (
          (typebuktitrf != "image/jpeg" &&
            typebuktitrf != "image/png" &&
            typebuktitrf != "image/gif") ||
          sizebuktitrf > 5120000
        ) {
          // alert('File harus berupa Format JPEG, PNG, GIF dan ukuran Max 5MB')
          swal({
            text: "File harus berupa Format JPEG, PNG, GIF dan ukuran Max 5MB",
            icon: "error",
            button: "OK",
          });
          $(".uploadTermin1").removeClass("loading");
          $(".uploadTermin1").removeClass("disabled");
          return false;
        }
      } else {
        // alert("Harap pilih file yang akan di upload terlebih dahulu...");
        swal({
          text: "Harap pilih file yang akan di upload terlebih dahulu",
          icon: "error",
          button: "OK",
        });
        return false;
      }

      if (tglTransaksi_Termin1 == "") {
        // alert("Tanggal Transaksi Wajib Diisi..");
        swal({
          text: "Tanggal Transaksi wajib diisi",
          icon: "error",
          button: "OK",
        });
        return false;
      }

      if (buktitrf) {
        form.buktitrf = await toBase64(buktitrf);
        $(".uploadTermin1").addClass("loading");
        $(".uploadTermin1").addClass("disabled");
        form.paramsTermin = "Termin1";
        submitDokProyek(form);
      }
    }

    async function handleUplSubmitPermintaanDoc() {
      // var formField = document.forms[1]
      var formField = document.querySelector('form[name="formPermintaanDoc"]');

      var filepermintaanDoc = formField.querySelector(
        'input[name="filepermintaanDoc"]'
      ).files[0];

      if (filepermintaanDoc) {
        var sizefilepermintaanDoc = formField.querySelector(
          'input[name="filepermintaanDoc"]'
        ).files[0].size;
        var typefilepermintaanDoc = formField.querySelector(
          'input[name="filepermintaanDoc"]'
        ).files[0].type;

        if (
          (typefilepermintaanDoc != "application/x-zip-compressed" &&
            typefilepermintaanDoc != "application/zip") ||
          sizefilepermintaanDoc > 10240000
        ) {
          swal({
            text: "File harus berupa Format zip dan ukuran Max 10MB",
            icon: "error",
            button: "OK",
          });
          $(".uploadpermintaanDoc").removeClass("loading");
          $(".uploadpermintaanDoc").removeClass("disabled");
          return false;
        }
      } else {
        swal({
          text: "Harap pilih file yang akan di upload terlebih dahulu",
          icon: "error",
          button: "OK",
        });
        return false;
      }

      if (filepermintaanDoc) {
        form.filepermintaanDoc = await toBase64(filepermintaanDoc);
        $(".uploadpermintaanDoc").addClass("loading");
        $(".uploadpermintaanDoc").addClass("disabled");
        submitPermintaanDoc(form);
      }
    }

    const handleUploadRequestDoc = async () => {
      let errors = {};
      let isValid = true;

      let document = [];

      this.state.listRequestDocument.map((item, i) => {
        if (!item.file && item.status == null) {
          console.log("UPLOAD DOC: ", item.content + ", File: " + null);
          isValid = false;
        } else {
          if (item.status == null) {
            var dataPush = {
              id_request: item.id_request,
              id_list: item.id_list,
              // content: item.content,
              file: item.file,
            };
            document.push(dataPush);
          }
        }
      });

      if (isValid) {
        let values = {};
        values.document_bisnis = document;
        submitRequestDocument(values);
      } else {
        swal({ text: "Dokumen belum lengkap!", icon: "error" });
      }
    };

    const submitRequestDocument = async (values) => {
      // console.log('DOKUMEN: ', JSON.stringify(values.document_bisnis))
      // return

      $(".uploadpermintaanDoc").addClass("loading");
      $(".uploadpermintaanDoc").addClass("disabled");

      values.id_project = dataDetailPermintaanDoc.id_project;
      values.user_id = userinfo.user_id;

      let config = {
        headers: {
          Authorization: localStorage.token,
          "content-type": "application/json",
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Methods": "POST",
        },
        maxContentLength: 52428890,
        timeout: 10000000,
      };

      axios
        .post(
          BASE_URL + "/customer/pengelola/document/upload_document_bisnis",
          values,
          config
        )
        .then((resp) => {
          $(".uploadpermintaanDoc").removeClass("loading");
          $(".uploadpermintaanDoc").removeClass("disabled");

          if (resp.data.success == false) {
            swal({ text: resp.data.message, icon: "error", button: "OK" });
          } else {
            swal({ text: resp.data.message, icon: "success", button: "OK" });

            console.log("DATA_RESPON", JSON.stringify(resp.data.data));

            setTimeout(() => {
              clickbackProyekBrjln();
              this.tabinformtabstatus();
            }, 1000);
          }
        })
        .catch((error) => {
          $(".uploadpermintaanDoc").removeClass("loading");
          $(".uploadpermintaanDoc").removeClass("disabled");

          try {
            let errorStatusCode = error.response.status;

            if (errorStatusCode === 401) {
              setTimeout(() => {
                window.location.href = "/Signin";
                localStorage.removeItem("token");
                localStorage.clear();

                this.props.dispatch({
                  type: "AUTH_USER_LOGOUT",
                });
              }, 500);
            } else {
              let errorBody = error.response.data;
              swal({ text: errorBody.message, icon: "error" });
            }
          } catch (error) {
            swal({ text: "Error tidak terdefinisi!", icon: "error" });
          }
        });
    };

    const defaultTabs = () => {
      var defaultTabs = "informasi";
      let urlElelement = window.location.href.split("#");
      if (urlElelement[1] == "submit") {
        if (dataIsComplete == 5 && verified_advance == 3) {
          defaultTabs = "daftarpengajuan";
          this.handleSelectTab("daftarpengajuan");
        } else {
          defaultTabs = "informasi";
        }
      } else {
        if (dataIsComplete == 5 && verified_advance == 3 && agreement == 1) {
          defaultTabs = "daftarpengajuan";
          this.handleSelectTab("daftarpengajuan");
        } else {
          defaultTabs = "informasi";
        }
      }

      return defaultTabs;
    };

    return (
      <>
        <Tabs
          defaultActiveKey={defaultTabs}
          id="tab-inform"
          onSelect={this.handleSelectTab}
        >
          {dataIsComplete === 5 &&
          verified_advance === 3 &&
          agreement === 1 ? null : (
            <Tab eventKey="informasi" title="Informasi">
              <TabInformasi
                userinfo={userinfo}
                handleShowModalAgreement={handleShowModalAgreement}
              />

              <ModalForm
                size="lg"
                style={{ borderRadius: "30px" }}
                show={this.state.showModalAgreement}
                onHide={handleCloseModalAgreement}
                animation={false}
              >
                <Form
                  name="formAgreement"
                  onSubmit={(e) => e.preventDefault()}
                  encType="multipart/form-data"
                  autoComplete="off"
                >
                  <ModalForm.Header closeButton>
                    <ModalForm.Title></ModalForm.Title>
                  </ModalForm.Header>
                  <ModalForm.Body>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.state.agreementContent,
                      }}
                    />
                  </ModalForm.Body>
                  <ModalForm.Footer>
                    <Button color="blue" onClick={handleCloseModalAgreement}>
                      Keluar
                    </Button>
                    <Button color="green" onClick={handleDirectSign}>
                      Selanjutnya
                    </Button>
                  </ModalForm.Footer>
                </Form>
              </ModalForm>

              <ModalForm
                size="lg"
                style={{ borderRadius: "30px" }}
                show={this.state.showModalRegisterSigned}
                onHide={handleCloseModalRegisterSigned}
                animation={false}
              >
                <Form
                  name="formRegisterSigned"
                  onSubmit={(e) => e.preventDefault()}
                  encType="multipart/form-data"
                  autoComplete="off"
                >
                  <ModalForm.Body>
                    <span>
                      Mohon konfirmasi ulang Foto Selfie dan Foto KTP untuk
                      melanjutkan proses penandatanganan.
                    </span>
                    <br />
                    <p>
                      <strong>#Foto Selfie</strong>
                    </p>
                    <CameraFeed sendFile={uploadImage} />
                    <span style={{ color: "red", fontSize: 11 }}>
                      {this.state.errorMessage["foto_selfie"]}
                    </span>

                    <p style={{ marginTop: "20px" }}>
                      <strong>#Foto KTP</strong>
                    </p>
                    <Form.Control
                      type="file"
                      name="foto_ktp"
                      onChange={handleFileChange}
                      accept={"image/jpg, image/png"}
                    />
                    <span style={{ color: "red", fontSize: 11 }}>
                      {this.state.errorMessage["foto_ktp"]}
                    </span>
                  </ModalForm.Body>
                  <ModalForm.Footer>
                    <Button
                      color="blue"
                      onClick={handleCloseModalRegisterSigned}
                    >
                      Keluar
                    </Button>
                    <Button color="green" onClick={handleSubmitRegisterSigned}>
                      Submit
                    </Button>
                  </ModalForm.Footer>
                </Form>
              </ModalForm>
            </Tab>
          )}

          <Tab eventKey="daftarpengajuan" title="Pengajuan">
            <TabPengajuan
              userinfo={userinfo}
              dataCategory={this.state.dataCategory}
              listPengajuan={this.state.listPengajuan}
            />
          </Tab>

          <Tab eventKey="transaksi" title="Transaksi">
            {dataIsComplete === 5 ? (
              <>
                {agreement === 1 ? (
                  <>
                    {hideDivStatusPryk === "close" ? (
                      <Container id="fonts" className="divStatusPryk">
                        <Row className="justify-content-md-center">
                          <Col xs md="12">
                            <Card.Group style={{ marginTop: "20px" }}>
                              {this.state &&
                              this.state.dataProyekPengelola &&
                              this.state.dataProyekPengelola.length > 0 ? (
                                this.state.dataProyekPengelola.map((item) => (
                                  <Card
                                    id="fonts"
                                    className="col-md-12"
                                    key={item.id_project}
                                  >
                                    <Card.Content>
                                      <Row>
                                        <Col id="fonts" xs md="9">
                                          <Image
                                            floated="left"
                                            className="imgStatusSubmitProyek"
                                            size="small"
                                            src={
                                              item.proyek_data.length > 0
                                                ? item.proyek_data[0].nama_file
                                                : ""
                                            }
                                          />

                                          <Card.Header>
                                            <Header
                                              id="fonts"
                                              as="h4"
                                              color="blue"
                                            >
                                              {item.nama_project}
                                            </Header>
                                          </Card.Header>
                                          <br />
                                          <Card.Meta
                                            id="fonts"
                                            className="meta IDProyek"
                                          >
                                            ID Proyek
                                          </Card.Meta>
                                          <Card.Description>
                                            <Header id="fonts" as="h4">
                                              {item.id_project}
                                            </Header>
                                            <br />
                                            <Card.Meta>
                                              {item.created_at}
                                            </Card.Meta>
                                          </Card.Description>
                                        </Col>

                                        {item.status === 1 ||
                                        item.status === 2 ||
                                        item.status === 3 ? (
                                          <Col
                                            xs
                                            md="3"
                                            className="justify-content-md-center"
                                            style={{
                                              textAlign: "center",
                                              marginTop: "0px",
                                            }}
                                          >
                                            <Header
                                              id="fonts"
                                              as="h4"
                                              color="blue"
                                            >
                                              Status
                                              <div
                                                id="fonts"
                                                className="sub header"
                                                style={{ color: "#214981" }}
                                              >
                                                Penilaian
                                              </div>
                                              <div
                                                id="fonts"
                                                className="divBtn"
                                              >
                                                <Button
                                                  id="fonts"
                                                  color="blue"
                                                  size="mini"
                                                  disabled
                                                >
                                                  Detail
                                                </Button>
                                              </div>
                                            </Header>
                                          </Col>
                                        ) : (
                                          ""
                                        )}

                                        {item.status === 4 ? (
                                          <Col
                                            xs
                                            md="3"
                                            className="justify-content-md-center"
                                            style={{
                                              textAlign: "center",
                                              marginTop: "0px",
                                            }}
                                          >
                                            <Header
                                              id="fonts"
                                              as="h4"
                                              color="blue"
                                            >
                                              Status
                                              <div
                                                id="fonts"
                                                className="sub header"
                                                style={{ color: "#214981" }}
                                              >
                                                Penilaian
                                              </div>
                                              <div
                                                id="fonts"
                                                className="sub header"
                                                style={{ color: "#214981" }}
                                              >
                                                <i className="itextsts">
                                                  (Permintaan Dokumen Lengkap)
                                                </i>
                                              </div>
                                              <div
                                                id="fonts"
                                                className="divBtn56"
                                              >
                                                <Button
                                                  className={
                                                    "btnDetailPermintaanDoc" +
                                                    item.id_project
                                                  }
                                                  id="fonts"
                                                  color="blue"
                                                  size="mini"
                                                  onClick={(event) => {
                                                    this.clickDetailPermintaanDoc(
                                                      item.id_project,
                                                      "dataDetailPermintaanDoc"
                                                    );
                                                  }}
                                                >
                                                  Detail
                                                </Button>
                                              </div>
                                            </Header>
                                          </Col>
                                        ) : (
                                          ""
                                        )}

                                        {item.status === 5 ? (
                                          <Col
                                            xs
                                            md="3"
                                            className="justify-content-md-center"
                                            style={{
                                              textAlign: "center",
                                              marginTop: "0px",
                                            }}
                                          >
                                            <Header
                                              id="fonts"
                                              as="h4"
                                              color="blue"
                                            >
                                              Status
                                              {/* <Progress percent={item.persentase_slot} progress className="progressProjectStatus56" /> */}
                                              <div
                                                id="fonts"
                                                className="sub header"
                                                style={{ color: "#214981" }}
                                              >
                                                Disetujui
                                              </div>
                                              <div
                                                id="fonts"
                                                className="sub header"
                                                style={{ color: "#214981" }}
                                              >
                                                <i className="itextsts">
                                                  (Proses persiapan campaign)
                                                </i>
                                              </div>
                                              <div
                                                id="fonts"
                                                className="divBtn56"
                                              >
                                                <Button
                                                  className={
                                                    "btnDetailStatusPryk" +
                                                    item.id_project
                                                  }
                                                  id="fonts"
                                                  color="blue"
                                                  size="mini"
                                                  onClick={(event) => {
                                                    this.clickDetailStatusPryk(
                                                      item.id_project,
                                                      "dataDetailStatusPryk"
                                                    );
                                                  }}
                                                >
                                                  Detail
                                                </Button>
                                              </div>
                                            </Header>
                                          </Col>
                                        ) : (
                                          ""
                                        )}

                                        {item.status === 6 ? (
                                          <Col
                                            xs
                                            md="3"
                                            className="justify-content-md-center"
                                            style={{
                                              textAlign: "center",
                                              marginTop: "0px",
                                            }}
                                          >
                                            <Header
                                              id="fonts"
                                              as="h4"
                                              color="blue"
                                            >
                                              Status
                                              <div
                                                id="fonts"
                                                className="sub header"
                                                style={{ color: "#214981" }}
                                              >
                                                Penawaran{" "}
                                                {Number.isInteger(
                                                  item.persentase_slot
                                                )
                                                  ? "ok"
                                                  : "tidak"}
                                              </div>
                                              <Progress
                                                percent={item.persentase_slot}
                                                progress
                                                className="progressProjectStatus56"
                                                style={{
                                                  paddingLeft:
                                                    item.persentase_slot < 21 &&
                                                    Number.isInteger(
                                                      item.persentase_slot
                                                    )
                                                      ? "10px"
                                                      : item.persentase_slot <
                                                          21 &&
                                                        !Number.isInteger(
                                                          item.persentase_slot
                                                        )
                                                      ? "20px"
                                                      : "0px",
                                                }}
                                              />
                                              {/* <div id="fonts" className="sub header" style={{ color: '#214981' }}>
                                                                                                <i className="itextsts">&nbsp;</i>
                                                                                            </div> */}
                                              <div
                                                id="fonts"
                                                className="divBtn56"
                                              >
                                                <Button
                                                  className={
                                                    "btnDetailStatusPryk" +
                                                    item.id_project
                                                  }
                                                  id="fonts"
                                                  color="blue"
                                                  size="mini"
                                                  onClick={(event) => {
                                                    this.clickDetailStatusPryk(
                                                      item.id_project,
                                                      "dataDetailStatusPryk"
                                                    );
                                                  }}
                                                >
                                                  Detail
                                                </Button>
                                              </div>
                                            </Header>
                                          </Col>
                                        ) : (
                                          ""
                                        )}

                                        {item.status == 7 ? (
                                          <Col
                                            xs
                                            md="3"
                                            className="justify-content-md-center"
                                            style={{
                                              textAlign: "center",
                                              marginTop: "0px",
                                            }}
                                          >
                                            <Header
                                              id="fonts"
                                              as="h4"
                                              color="blue"
                                            >
                                              Status
                                              <Progress
                                                percent={item.persentase_slot}
                                                progress
                                                className="progressProjectStatus56"
                                                style={{
                                                  paddingLeft:
                                                    item.persentase_slot < 21 &&
                                                    Number.isInteger(
                                                      item.persentase_slot
                                                    )
                                                      ? "10px"
                                                      : item.persentase_slot <
                                                          21 &&
                                                        !Number.isInteger(
                                                          item.persentase_slot
                                                        )
                                                      ? "20px"
                                                      : "0px",
                                                }}
                                              />
                                              <div
                                                id="fonts"
                                                className="sub header"
                                                style={{ color: "#214981" }}
                                              >
                                                <i className="itextsts">
                                                  (Penggalangan Dana Selesai)
                                                </i>
                                              </div>
                                              <div
                                                id="fonts"
                                                className="divBtn56"
                                              >
                                                <Button
                                                  className={
                                                    "btnDetailStatusPrykSelesai" +
                                                    item.id_project
                                                  }
                                                  id="fonts"
                                                  color="blue"
                                                  size="mini"
                                                  onClick={(event) => {
                                                    this.clickDetailStatusPrykSelesai(
                                                      item.id_project,
                                                      "dataDetailStatusPrykSelesai"
                                                    );
                                                  }}
                                                >
                                                  Detail
                                                </Button>
                                              </div>
                                            </Header>
                                          </Col>
                                        ) : (
                                          ""
                                        )}

                                        {item.status == 99 ||
                                        item.status == 98 ? (
                                          <Col
                                            xs
                                            md="3"
                                            className="justify-content-md-center"
                                            style={{
                                              textAlign: "center",
                                              marginTop: "0px",
                                            }}
                                          >
                                            <Header
                                              id="fonts"
                                              as="h4"
                                              color="blue"
                                            >
                                              Status
                                              <div
                                                id="fonts"
                                                className="sub header"
                                                style={{ color: "#dc3545" }}
                                              >
                                                Rejected
                                              </div>
                                            </Header>
                                          </Col>
                                        ) : (
                                          ""
                                        )}

                                        {item.status == 100 ? (
                                          <Col
                                            xs
                                            md="3"
                                            className="justify-content-md-center"
                                            style={{
                                              textAlign: "center",
                                              marginTop: "0px",
                                            }}
                                          >
                                            <Header
                                              id="fonts"
                                              as="h4"
                                              color="blue"
                                            >
                                              Status
                                              <div
                                                id="fonts"
                                                className="sub header"
                                                style={{ color: "#02A852" }}
                                              >
                                                Proyek Selesai
                                              </div>
                                            </Header>
                                          </Col>
                                        ) : (
                                          ""
                                        )}

                                        {item.status == 8 ? (
                                          <Col
                                            xs
                                            md="3"
                                            className="justify-content-md-center"
                                            style={{
                                              textAlign: "center",
                                              marginTop: "0px",
                                            }}
                                          >
                                            <Header
                                              id="fonts"
                                              as="h4"
                                              color="blue"
                                            >
                                              Status
                                              <Progress
                                                percent={item.persentase_slot}
                                                progress
                                                className="progressProjectStatus56"
                                              />
                                              <div
                                                id="fonts"
                                                className="sub header"
                                                style={{ color: "#214981" }}
                                              >
                                                <i className="itextsts">
                                                  (Akad Sudah Siap)
                                                </i>
                                              </div>
                                              <div
                                                id="fonts"
                                                className="divBtn56"
                                              >
                                                <Button
                                                  className={
                                                    "btnDetailStatusPrykSelesai" +
                                                    item.id_project
                                                  }
                                                  id="fonts"
                                                  color="blue"
                                                  size="mini"
                                                  onClick={(event) => {
                                                    this.clickDetailStatusPrykSelesai(
                                                      item.id_project,
                                                      "dataDetailStatusPrykSelesai"
                                                    );
                                                  }}
                                                >
                                                  Detail
                                                </Button>
                                              </div>
                                            </Header>
                                          </Col>
                                        ) : (
                                          ""
                                        )}

                                        {item.status == 9 ||
                                        item.status == 10 ||
                                        item.status == 11 ? (
                                          <Col
                                            xs
                                            md="3"
                                            className="justify-content-md-center"
                                            style={{
                                              textAlign: "center",
                                              marginTop: "0px",
                                            }}
                                          >
                                            <Header
                                              id="fonts"
                                              as="h4"
                                              color="blue"
                                            >
                                              Status
                                              {item.data_termin.length > 0 &&
                                              item.data_termin != "none" ? (
                                                <>
                                                  <div
                                                    id="fonts"
                                                    className="sub header"
                                                    style={{ color: "#214981" }}
                                                  >
                                                    Proyek Berjalan
                                                    <br />
                                                    {item.data_termin.map(
                                                      (itm, idx) => {
                                                        {
                                                          return itm.status ==
                                                            2 ? (
                                                            <Icon
                                                              key={idx}
                                                              name="circle"
                                                              className="dottedberj"
                                                            ></Icon>
                                                          ) : (
                                                            <Icon
                                                              key={idx}
                                                              name="circle"
                                                              className="dottedgrey"
                                                            ></Icon>
                                                          );
                                                        }
                                                      }
                                                    )}
                                                  </div>
                                                  <div
                                                    id="fonts"
                                                    className="divBtn"
                                                  >
                                                    <Button
                                                      className={
                                                        "btnDetailPrykBrjln" +
                                                        item.id_project
                                                      }
                                                      id="fonts"
                                                      color="blue"
                                                      size="mini"
                                                      onClick={(event) => {
                                                        this.clickDetailPrykBrjln(
                                                          item.id_project,
                                                          "dataDetailPrykBrjln"
                                                        );
                                                      }}
                                                    >
                                                      Detail
                                                    </Button>
                                                  </div>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </Header>
                                          </Col>
                                        ) : (
                                          ""
                                        )}
                                      </Row>
                                    </Card.Content>
                                  </Card>
                                ))
                              ) : (
                                <Container
                                  style={{
                                    paddingTop: "100px",
                                    paddingBottom: "200px",
                                  }}
                                >
                                  <div
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                      textAlign: "center",
                                    }}
                                  >
                                    Belum ada transaksi yang dapat di tampilkan
                                  </div>
                                  <div
                                    style={{
                                      fontSize: "14px",
                                      textAlign: "center",
                                      lineHeight: "1.5em",
                                    }}
                                  >
                                    Silahkan lakukan pengajuan pendanaan..
                                  </div>
                                </Container>
                              )}

                              {this.state &&
                              this.state.dataProyekPengelolaR &&
                              this.state.dataProyekPengelolaR.length > 0 &&
                              this.state.dataProyekPengelolaR.length > 4 ? (
                                <>
                                  <Container
                                    align="center"
                                    className="pagSttsPnglla"
                                  >
                                    <Row>
                                      <Col xs md="12">
                                        <Pagination
                                          size="mini"
                                          activePage={this.state.activePage}
                                          totalPages={Math.ceil(
                                            this.state.dataProyekPengelolaR
                                              .length / 4
                                          )}
                                          onPageChange={this.PaginationClick}
                                        />
                                      </Col>
                                    </Row>
                                  </Container>
                                </>
                              ) : null}
                            </Card.Group>
                          </Col>
                        </Row>
                      </Container>
                    ) : (
                      <>
                        {hideDivStatusPryk == "open" ? (
                          <Container id="fonts" className="divDetailTermin1">
                            <Row className="justify-content-md-center">
                              <Col xs md="12">
                                <Breadcrumb className="brPrykBerjalan">
                                  <Breadcrumb.Section
                                    link
                                    onClick={clickbackProyekBrjln}
                                  >
                                    Transaksi
                                  </Breadcrumb.Section>
                                  <Breadcrumb.Divider />
                                  <Breadcrumb.Section active>
                                    Detail
                                  </Breadcrumb.Section>
                                </Breadcrumb>
                                <Breadcrumb
                                  className="brPrykBerjalan"
                                  style={{ float: "right" }}
                                >
                                  <Breadcrumb.Section
                                    link
                                    onClick={clickbackProyekBrjln}
                                  >
                                    {"<< Back"}
                                  </Breadcrumb.Section>
                                </Breadcrumb>
                                <div className="divDetPengelola">
                                  <div className="judulDetPengelola">
                                    <span>Detail Penerbit</span>
                                  </div>
                                  <div className="divNamaPic">
                                    <div className="jPic">Nama PIC</div>
                                    <div className="nPic">
                                      {dataDetailStatusPryk.full_name}
                                    </div>
                                  </div>
                                  <div className="divNamaPic">
                                    <div className="jPic">Kode Penerbit</div>
                                    <div className="nPic">
                                      ID. {dataDetailStatusPryk.user_id}
                                    </div>
                                  </div>
                                  <div className="divNamaPic">
                                    <div className="jPic">Nama Penerbit</div>
                                    <div className="nPic">
                                      {dataDetailStatusPryk.penerbit}
                                    </div>
                                  </div>
                                  {/* <div className="divNamaPic">
                                                                    <div className="jPic">Username PrivyID</div>
                                                                    <div className="nPic">-</div>
                                                                </div> */}
                                </div>
                                <br />
                                <br />
                                <div className="divDetProyek">
                                  <div className="judulDetPengelola">
                                    <span>Detail Proyek</span>
                                  </div>
                                  <div className="divNamaPic">
                                    <div className="jPic">Nama Proyek</div>
                                    <div className="nPic">
                                      {dataDetailStatusPryk.nama_project}
                                    </div>
                                  </div>
                                  <div className="divNamaPic">
                                    <div className="jPic">Kode Proyek</div>
                                    <div className="nPic">
                                      {dataDetailStatusPryk.id_project}
                                    </div>
                                  </div>
                                  <div className="divNamaPic">
                                    <div className="jPic">Kategori Proyek</div>
                                    <div className="nPic">
                                      {dataDetailStatusPryk.category}
                                    </div>
                                  </div>
                                  <div className="divNamaPic">
                                    <div className="jPic">Tipe Efek</div>
                                    <div className="nPic">
                                      {dataDetailStatusPryk.type_efek == "EBU"
                                        ? "Efek Bersifat Utang (EBU)"
                                        : ""}
                                      {dataDetailStatusPryk.type_efek == "EBE"
                                        ? "Efek Bersifat Ekuitas (EBE)"
                                        : ""}
                                    </div>
                                  </div>
                                  <div className="divNamaPic">
                                    <div className="jPic">
                                      Dana Yang Dibutuhkan
                                    </div>
                                    <div className="nPic">
                                      {this.formatRupiah(
                                        dataDetailStatusPryk.capital_needed
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <br />
                                <br />
                                {dataDetailStatusPryk.status == 6 ? (
                                  <>
                                    <div className="divDetProyek">
                                      <div className="judulDetPengelola">
                                        <span>Detail Pembiayaan</span>
                                      </div>
                                      <div className="divNamaPic">
                                        <div className="jPic">Status</div>
                                        <div className="nPic">
                                          <Row>
                                            <Col md="8">
                                              Crowdfunding Berjalan
                                            </Col>
                                            <Col md="4">
                                              <Progress
                                                percent={
                                                  dataDetailStatusPryk.persentase_slot
                                                }
                                                progress
                                                className="progressProjectStatusDet"
                                                style={{ margin: "0 0 0" }}
                                              />
                                            </Col>
                                          </Row>
                                        </div>
                                      </div>
                                      <div className="divNamaPic">
                                        <div className="jPic">
                                          Dana Yang Sudah Masuk
                                        </div>
                                        <div className="nPic">
                                          {this.formatRupiah(
                                            dataDetailStatusPryk.dana_masuk
                                          )}
                                        </div>
                                      </div>
                                      <div className="divNamaPic">
                                        <div className="jPic">
                                          Pemodal Yang Masuk
                                        </div>
                                        <div className="nPic">
                                          {dataDetailStatusPryk.total_pemodal}{" "}
                                          Pemodal
                                        </div>
                                      </div>
                                      <div className="divNamaPic">
                                        <div className="jPic">
                                          Batas Akhir Penggalangan Dana
                                        </div>
                                        <div className="nPic">
                                          {this.formatTanggal(
                                            dataDetailStatusPryk.date_akhir_val
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <br />
                                    <br />
                                    <div style={{ textAlign: "center" }}>
                                      <Button
                                        disabled
                                        className={
                                          "btnPencairanTermin1" +
                                          dataDetailStatusPryk.id_project
                                        }
                                        id="fonts"
                                        color="blue"
                                        size="mini"
                                        onClick={(event) => {
                                          this.clickTermin1(
                                            dataDetailStatusPryk.id_project,
                                            "dataDetailStatusPryk"
                                          );
                                        }}
                                        style={{ width: "25%" }}
                                      >
                                        Syarat Pencairan
                                      </Button>
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <Button
                                        disabled={
                                          dataDetailStatusPryk.status == 6
                                            ? false
                                            : true
                                        }
                                        color="blue"
                                        size="mini"
                                        href={
                                          "/Detail/" +
                                          dataDetailStatusPryk.id_project
                                        }
                                        target="_blank"
                                        style={{ width: "25%" }}
                                      >
                                        Lihat Campaign
                                      </Button>
                                    </div>
                                  </>
                                ) : null}
                              </Col>
                            </Row>
                          </Container>
                        ) : null}
                      </>
                    )}

                    {hideDivPermintaanDoc == "open" ? (
                      <Form
                        name="formPermintaanDoc"
                        onSubmit={(e) => e.preventDefault()}
                        encType="multipart/form-data"
                      >
                        <Container id="fonts">
                          <Row className="justify-content-md-center">
                            <Col md="12">
                              <Breadcrumb className="brPrykBerjalan">
                                <Breadcrumb.Section
                                  link
                                  onClick={clickbackProyekBrjln}
                                >
                                  Transaksi
                                </Breadcrumb.Section>
                                <Breadcrumb.Divider />
                                <Breadcrumb.Section active>
                                  Detail
                                </Breadcrumb.Section>
                              </Breadcrumb>
                              <Breadcrumb
                                className="brPrykBerjalan"
                                style={{ float: "right" }}
                              >
                                <Breadcrumb.Section
                                  link
                                  onClick={clickbackProyekBrjln}
                                >
                                  {"<< Back"}
                                </Breadcrumb.Section>
                              </Breadcrumb>
                              <div className="divDetPermintaanDoc">
                                <div className="judulDetPengelola">
                                  <span>Upload Dokumen Bisnis Lengkap</span>
                                </div>
                                <div className="divNamaPic">
                                  <div className="jPic">
                                    Berdasarkan hasil dari tim analisa bisnis
                                    kerjasama.co.id, mohon untuk upload beberapa
                                    dokumen yang kami butuhkan terkait pendanaan
                                    yang anda ajukan, diantaranya sebagai
                                    berikut :
                                  </div>
                                  <div className="jPic">
                                    <br />
                                    {/* <div id="contentNotifPermintaanDoc" style={{ padding: '10px' }}></div> */}
                                    <ol style={{ marginLeft: "-20px" }}>
                                      {this.state.listRequestDocument.length > 0
                                        ? this.state.listRequestDocument.map(
                                            (item, index) => (
                                              <>
                                                <li
                                                  style={{ marginTop: "5px" }}
                                                >
                                                  <div className="row">
                                                    <div className="col-8">
                                                      {item.content}
                                                    </div>
                                                    {item.document_status ==
                                                    null ? (
                                                      <div className="col-4 text-right">
                                                        <input
                                                          type="file"
                                                          name="filepermintaanDoc"
                                                          className="inputfile"
                                                          id={
                                                            "embedpollfileinput-" +
                                                            index
                                                          }
                                                          onChange={(event) => {
                                                            this.onFileDocumentChange(
                                                              event,
                                                              index
                                                            );
                                                            // $('#textFile-'+index).html(event.target.files[0].name)
                                                          }}
                                                          accept={
                                                            "application/pdf"
                                                          }
                                                        />
                                                        <label
                                                          htmlFor={
                                                            "embedpollfileinput-" +
                                                            index
                                                          }
                                                          className="ui button mini"
                                                        >
                                                          <Icon
                                                            className="upload"
                                                            color="green"
                                                          />
                                                          <span
                                                            id={
                                                              "textFileDoc-" +
                                                              index
                                                            }
                                                          >
                                                            Pilih File
                                                          </span>
                                                        </label>
                                                      </div>
                                                    ) : item.document_status ==
                                                      1 ? (
                                                      <div className="col-4 text-right">
                                                        <Message
                                                          size="small"
                                                          color="yellow"
                                                          compact
                                                        >
                                                          Sedang direview
                                                        </Message>
                                                      </div>
                                                    ) : null}
                                                  </div>
                                                  <hr
                                                    style={{
                                                      marginRight: "5px",
                                                    }}
                                                  />
                                                </li>
                                              </>
                                            )
                                          )
                                        : null}
                                    </ol>
                                    Note : <br />
                                    - Mohon dokumen yang diberikan hasil scan
                                    bukan foto <br />
                                    - Format file pdf, ukuran masing-masing file
                                    maksimal 500kb. <br />
                                    {/* - Gabungkan semua menjadi satu dengan format .zip */}
                                  </div>
                                </div>

                                {dokumen_lengkap === "ada" ? (
                                  <>
                                    <div className="divNamaPic">
                                      <div className="nPic">
                                        {/* <div className="ui middle aligned left aligned grid container">
                                                                                <div className="ui fluid segment grContainer">
                                                                                    <input type="file" name="filepermintaanDoc" className="inputfile" id="embedpollfileinput" onChange={(event) => { this.onFileChange(event, 99) }} />
                                                                                    <label htmlFor="embedpollfileinput" className="ui small blue right floated button">
                                                                                        <i className="ui upload icon"></i>
                                                                                        {filepermintaanDoc}
                                                                                    </label>
                                                                                </div>
                                                                                <div className="ui fluid segment grContainer" style={{ marginLeft: '20px' }}>
                                                                                    <Button id="fonts" color='blue' disabled className="uploadpermintaanDoc" size='small' type="button" onClick={handleUplSubmitPermintaanDoc}>Upload</Button>
                                                                                </div>
                                                                            </div> */}
                                        <div className="text-right">
                                          <Button
                                            id="fonts"
                                            color="blue"
                                            className="uploadpermintaanDoc"
                                            size="mini"
                                            type="button"
                                            onClick={handleUploadRequestDoc}
                                          >
                                            Upload
                                          </Button>
                                        </div>
                                      </div>
                                      <Message
                                        color="orange"
                                        style={{
                                          fontSize: "10px",
                                          width: "33%",
                                        }}
                                      >
                                        Berkas Dokumen Lengkap Sedang di Review
                                      </Message>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="divNamaPic">
                                      <div className="nPic">
                                        {/* <div className="ui middle aligned left aligned grid container">
                                                                                <div className="ui fluid segment grContainer">
                                                                                    <input type="file" name="filepermintaanDoc" className="inputfile" id="embedpollfileinput" onChange={(event) => { this.onFileChange(event, 99) }} />
                                                                                    <label htmlFor="embedpollfileinput" className="ui small blue right floated button">
                                                                                        <i className="ui upload icon"></i>
                                                                                        {filepermintaanDoc}
                                                                                    </label>
                                                                                </div>
                                                                                <div className="ui fluid segment grContainer" style={{ marginLeft: '20px' }}>
                                                                                    <Button id="fonts" color='blue' className="uploadpermintaanDoc" size='small' type="button" onClick={handleUplSubmitPermintaanDoc}>Upload</Button>
                                                                                </div>
                                                                            </div> */}
                                        <div className="text-right">
                                          <Button
                                            id="fonts"
                                            color="blue"
                                            className="uploadpermintaanDoc"
                                            size="mini"
                                            type="button"
                                            onClick={handleUploadRequestDoc}
                                          >
                                            Upload
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                              <br />
                            </Col>
                          </Row>
                        </Container>
                      </Form>
                    ) : null}

                    {hideDivPrykBrjln == "open" ? (
                      <Container id="fonts" className="divDetailTermin2">
                        <Row className="justify-content-md-center">
                          <Col xs md="12">
                            <Breadcrumb className="brPrykBerjalan">
                              <Breadcrumb.Section
                                link
                                onClick={clickbackProyekBrjln}
                              >
                                Status
                              </Breadcrumb.Section>
                              <Breadcrumb.Divider />
                              <Breadcrumb.Section active>
                                Detail
                              </Breadcrumb.Section>
                            </Breadcrumb>
                            <Breadcrumb
                              className="brPrykBerjalan"
                              style={{ float: "right" }}
                            >
                              <Breadcrumb.Section
                                link
                                onClick={clickbackProyekBrjln}
                              >
                                {"<< Back"}
                              </Breadcrumb.Section>
                            </Breadcrumb>
                            <div className="divDetPengelola">
                              <div className="judulDetPengelola">
                                <span>Detail Penerbit</span>
                              </div>
                              <div className="divNamaPic">
                                <div className="jPic">Nama PIC</div>
                                <div className="nPic">
                                  {dataDetailPrykBrjln.full_name}
                                </div>
                              </div>
                              <div className="divNamaPic">
                                <div className="jPic">Kode Penerbit</div>
                                <div className="nPic">
                                  ID. {dataDetailPrykBrjln.id_pengelola}
                                </div>
                              </div>
                              {/* <div className="divNamaPic">
                                                            <div className="jPic">Penerbit</div>
                                                            <div className="nPic">{dataDetailPrykBrjln.penerbit}</div>
                                                        </div> */}
                            </div>
                            <br />
                            <br />
                            <div className="divDetProyek">
                              <div className="judulDetPengelola">
                                <span>Detail Proyek</span>
                              </div>
                              <div className="divNamaPic">
                                <div className="jPic">Nama Proyek</div>
                                <div className="nPic">
                                  {dataDetailPrykBrjln.nama_project}
                                </div>
                              </div>
                              <div className="divNamaPic">
                                <div className="jPic">Kode Proyek</div>
                                <div className="nPic">
                                  {dataDetailPrykBrjln.id_project}
                                </div>
                              </div>
                              <div className="divNamaPic">
                                <div className="jPic">Kategori Proyek</div>
                                <div className="nPic">
                                  {/* {(dataDetailPrykBrjln.type_project == 0) ? "Property" : ""}
                                                            {(dataDetailPrykBrjln.type_project == 1) ? "Project Financing" : ""}
                                                            {(dataDetailPrykBrjln.type_project == 2) ? "UMKM" : ""} */}
                                  {dataDetailPrykBrjln.category}
                                </div>
                              </div>
                              <div className="divNamaPic">
                                <div className="jPic">Tipe Efek</div>
                                <div className="nPic">
                                  {dataDetailPrykBrjln.type_efek == "EBU"
                                    ? "Efek Bersifat Utang (EBU)"
                                    : ""}
                                  {dataDetailPrykBrjln.type_efek == "EBE"
                                    ? "Efek Bersifat Ekuitas (EBE)"
                                    : ""}
                                </div>
                              </div>
                              <div className="divNamaPic">
                                <div className="jPic">Dana Yang Dibutuhkan</div>
                                <div className="nPic">
                                  {this.formatRupiah(
                                    dataDetailPrykBrjln.capital_needed
                                  )}
                                </div>
                              </div>
                            </div>
                            <br />
                            <br />
                            <div className="divDetProyek">
                              <div className="judulDetPengelola">
                                <span>Detail Pembiayaan</span>
                              </div>
                              <div className="divNamaPic">
                                <div className="jPic">Status</div>
                                <div className="nPic">
                                  <Row>
                                    <Col md="12">Proyek Berjalan</Col>
                                  </Row>
                                </div>
                              </div>
                              <div className="divNamaPic">
                                <div className="jPic">
                                  Dana Yang Sudah Masuk
                                </div>
                                <div className="nPic">
                                  {this.formatRupiah(
                                    dataDetailPrykBrjln.dana_masuk
                                  )}
                                </div>
                              </div>
                              <div className="divNamaPic">
                                <div className="jPic">Pemodal Yang Masuk</div>
                                <div className="nPic">
                                  {dataDetailPrykBrjln.total_pemodal} Pemodal
                                </div>
                              </div>
                              <div className="divNamaPic">
                                <div className="jPic">
                                  Batas Akhir Penggalangan Dana
                                </div>
                                <div className="nPic">
                                  {this.formatTanggal(
                                    dataDetailPrykBrjln.date_akhir_val
                                  )}
                                </div>
                              </div>
                            </div>
                            <br />
                            <br />
                            <div className="divDetPengelola">
                              <div className="judulDetPengelola">
                                <span>Detail Pencairan</span>
                              </div>
                              {dataDetailPrykBrjln.data_termin.map(
                                (itm, idx) => {
                                  {
                                    return itm.status == 2 ? (
                                      <div className="divNamaPic">
                                        <div className="jPic">
                                          Termin Ke - {itm.termin_step}
                                        </div>
                                        <div className="nPic">
                                          <Row>
                                            <Col md="10">
                                              {this.formatRupiah(itm.amount)}
                                            </Col>
                                            {/* <Col md="2">
                                                                                        <i className="tglPencairan">{this.formatTanggal(itm.create_at)}</i>
                                                                                    </Col> */}
                                          </Row>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="divNamaPic">
                                        <div className="jPic">
                                          Termin Ke - {itm.termin_step}
                                        </div>
                                        <div className="nPic"> - </div>
                                      </div>
                                    );
                                  }
                                }
                              )}
                            </div>
                            <br />
                            <br />
                            <div style={{ textAlign: "center" }}>
                              {dataDetailPrykBrjln.status_termin === "Belum" ? (
                                <>
                                  <Button
                                    className={
                                      "btnPencairanTermin2" +
                                      dataDetailPrykBrjln.id_project
                                    }
                                    id="fonts"
                                    color="blue"
                                    size="mini"
                                    onClick={(event) => {
                                      this.clickTermin2(
                                        dataDetailPrykBrjln.id_project,
                                        "dataDetailPrykBrjln"
                                      );
                                    }}
                                    style={{ width: "25%" }}
                                  >
                                    Syarat Pencairan
                                  </Button>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <Button
                                    color="blue"
                                    size="mini"
                                    href={
                                      "/Detail/" +
                                      dataDetailPrykBrjln.id_project
                                    }
                                    target="_blank"
                                    style={{ width: "25%" }}
                                  >
                                    Lihat Campaign
                                  </Button>
                                </>
                              ) : (
                                <>
                                  <Button
                                    className={
                                      "btnPencairanRealisasiHslPryk" +
                                      dataDetailPrykBrjln.id_project
                                    }
                                    id="fonts"
                                    color="blue"
                                    size="mini"
                                    onClick={(event) => {
                                      this.clickRealisasiHslPryk(
                                        dataDetailPrykBrjln.id_project,
                                        "dataDetailPrykBrjln"
                                      );
                                    }}
                                    style={{ width: "25%" }}
                                  >
                                    Konfirmasi Proyek Selesai
                                  </Button>
                                </>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    ) : null}

                    {hideDivPryktglBrakhir == "open" ? (
                      <Container
                        id="fonts"
                        className="divDetailTermin1PrykBrakhir"
                      >
                        <Modal
                          className="modalTolakPrivy"
                          size={size}
                          open={openTolakPryk}
                          onClose={closeTolakPryk}
                          closeIcon
                          closeOnEscape={closeOnEscape}
                          closeOnDimmerClick={closeOnDimmerClick}
                        >
                          <Modal.Header>Apa Alasannya ?</Modal.Header>
                          <Modal.Content>
                            <Form>
                              <TextArea placeholder="Ketik Alasannya di sini.." />
                            </Form>
                          </Modal.Content>
                          <Modal.Actions>
                            <Button
                              color="green"
                              circular
                              content="Submit"
                              onClick={closeTolakPryk}
                            />
                            <Link
                              to={{
                                pathname: "/Pembayaran/TagihanVA",
                                data: {},
                              }}
                            >
                              <Button
                                id="btnPembayaranAuto"
                                className="col-md-11 bayarButton"
                                size="medium"
                                color="blue"
                                style={{ display: "none" }}
                              >
                                Submit
                              </Button>
                            </Link>
                          </Modal.Actions>
                        </Modal>
                        <Row className="justify-content-md-center">
                          <Col xs md="12">
                            <Breadcrumb className="brPrykBerjalan">
                              <Breadcrumb.Section
                                link
                                onClick={clickbackProyekBrjln}
                              >
                                Status
                              </Breadcrumb.Section>
                              <Breadcrumb.Divider />
                              <Breadcrumb.Section active>
                                Detail
                              </Breadcrumb.Section>
                            </Breadcrumb>
                            <Breadcrumb
                              className="brPrykBerjalan"
                              style={{ float: "right" }}
                            >
                              <Breadcrumb.Section
                                link
                                onClick={clickbackProyekBrjln}
                              >
                                {"<< Back"}
                              </Breadcrumb.Section>
                            </Breadcrumb>
                            <div className="divDetPengelola">
                              <div className="judulDetPengelola">
                                <span>Detail Penerbit</span>
                              </div>
                              <div className="divNamaPic">
                                <div className="jPic">Nama PIC</div>
                                <div className="nPic">
                                  {dataDetailPryktglBrakhir.full_name}
                                </div>
                              </div>
                              <div className="divNamaPic">
                                <div className="jPic">Kode Penerbit</div>
                                <div className="nPic">
                                  ID. {dataDetailPryktglBrakhir.id_pengelola}
                                </div>
                              </div>
                              {/* <div className="divNamaPic">
                                                            <div className="jPic">Username PrivyID</div>
                                                            <div className="nPic">-</div>
                                                        </div> */}
                            </div>
                            <br />
                            <br />
                            <div className="divDetProyek">
                              <div className="judulDetPengelola">
                                <span>Detail Proyek</span>
                              </div>
                              <div className="divNamaPic">
                                <div className="jPic">Nama Proyek</div>
                                <div className="nPic">
                                  {dataDetailPryktglBrakhir.nama_project}
                                </div>
                              </div>
                              <div className="divNamaPic">
                                <div className="jPic">Kode Proyek</div>
                                <div className="nPic">
                                  {dataDetailPryktglBrakhir.id_project}
                                </div>
                              </div>
                              <div className="divNamaPic">
                                <div className="jPic">Kategori Proyek</div>
                                <div className="nPic">
                                  {/* {(dataDetailPryktglBrakhir.type_project == 0) ? "Property" : ""}
                                                            {(dataDetailPryktglBrakhir.type_project == 1) ? "Project Financing" : ""}
                                                            {(dataDetailPryktglBrakhir.type_project == 2) ? "UMKM" : ""} */}
                                  {dataDetailPryktglBrakhir.category}
                                </div>
                              </div>
                              <div className="divNamaPic">
                                <div className="jPic">Tipe Efek</div>
                                <div className="nPic">
                                  {dataDetailPryktglBrakhir.type_efek == "EBU"
                                    ? "Efek Bersifat Utang (EBU)"
                                    : ""}
                                  {dataDetailPryktglBrakhir.type_efek == "EBE"
                                    ? "Efek Bersifat Ekuitas (EBE)"
                                    : ""}
                                </div>
                              </div>
                              <div className="divNamaPic">
                                <div className="jPic">Dana Yang Dibutuhkan</div>
                                <div className="nPic">
                                  {this.formatRupiah(
                                    dataDetailPryktglBrakhir.capital_needed
                                  )}
                                </div>
                              </div>
                            </div>
                            <br />
                            <br />
                            <div className="divDetProyek">
                              <div className="judulDetPengelola">
                                <span>Detail Pembiayaan</span>
                              </div>
                              <div className="divNamaPic">
                                <div className="jPic">Status</div>
                                <div className="nPic">
                                  <Row>
                                    <Col md="8">Crowdfunding Berjalan</Col>
                                    <Col md="4">
                                      <Progress
                                        percent={70}
                                        progress
                                        className="progressProjectStatusDet"
                                        style={{ margin: "0 0 0" }}
                                      />
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                              <div className="divNamaPic">
                                <div className="jPic">
                                  Dana Yang Sudah Masuk
                                </div>
                                <div className="nPic">Rp. 200.000.000</div>
                              </div>
                              <div className="divNamaPic">
                                <div className="jPic">Pemodal Yang Masuk</div>
                                <div className="nPic">120 Pemodal</div>
                              </div>
                              <div className="divNamaPic">
                                <div className="jPic">
                                  Batas Akhir Penggalangan Dana
                                </div>
                                <div className="nPic">26 Juli 2010</div>
                              </div>
                            </div>
                            <br />
                            <br />
                            <div style={{ textAlign: "center" }}>
                              <Button
                                className={
                                  "btnPencairanTermin1" +
                                  dataDetailPryktglBrakhir.id_project
                                }
                                id="fonts"
                                color="blue"
                                size="mini"
                                onClick={(event) => {
                                  this.clickTermin1(
                                    dataDetailPryktglBrakhir.id_project,
                                    "dataDetailPryktglBrakhir"
                                  );
                                }}
                              >
                                Ajukan Pencairan Termin ke-1
                              </Button>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <Button
                                id="fonts"
                                color="blue"
                                size="mini"
                                onClick={openMdlTolakPryk}
                              >
                                Ajukan Pembatalan Proyek
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    ) : (
                      ""
                    )}

                    {hideDivTermin1 == "open" ? (
                      <Form
                        name="formTermin1"
                        onSubmit={(e) => e.preventDefault()}
                        encType="multipart/form-data"
                      >
                        <Container id="fonts">
                          <Row className="justify-content-md-center">
                            <Col md="12">
                              <Breadcrumb className="brPrykBerjalan">
                                <Breadcrumb.Section
                                  link
                                  onClick={clickbackProyekBrjln}
                                >
                                  Status
                                </Breadcrumb.Section>
                                <Breadcrumb.Divider />
                                <Breadcrumb.Section
                                  onClick={(event) => {
                                    this.clickDetailStatusPrykSelesai(
                                      dataDetailStatusPrykSelesai.id_project,
                                      "dataDetailStatusPrykSelesai"
                                    );
                                  }}
                                >
                                  Detail
                                </Breadcrumb.Section>
                                <Breadcrumb.Divider />
                                <Breadcrumb.Section active>
                                  Pengajuan Pencairan
                                </Breadcrumb.Section>
                              </Breadcrumb>
                              <Breadcrumb
                                className="brPrykBerjalan"
                                style={{ float: "right" }}
                              >
                                <Breadcrumb.Section
                                  link
                                  onClick={(event) => {
                                    this.clickDetailStatusPrykSelesai(
                                      dataDetailStatusPrykSelesai.id_project,
                                      "dataDetailStatusPrykSelesai"
                                    );
                                  }}
                                >
                                  {"<< Back"}
                                </Breadcrumb.Section>
                              </Breadcrumb>
                              <div className="divDetTermin1">
                                <div className="judulDetTermin1">
                                  <span>Tanda Tangan E-Kontrak</span>
                                </div>
                                <div className="divNamaPic">
                                  <div className="jPic">
                                    Total Akad <i className="t2">:</i>{" "}
                                    <i className="r2">
                                      {
                                        dataDetailStatusPrykSelesai.total_pemodal
                                      }
                                    </i>
                                  </div>
                                  <div className="nPic">
                                    Akad Tersedia Saat Ini{" "}
                                    <i
                                      className="t2"
                                      style={{ marginLeft: "43px" }}
                                    >
                                      :
                                    </i>{" "}
                                    <i className="r2">0</i>
                                  </div>
                                  <br />
                                  {dataHUjroh.ujroh_status > 0 ? (
                                    <Button
                                      disabled
                                      color="blue"
                                      size="mini"
                                      href={wbuFile}
                                      download={wbuFile}
                                      target="_blank"
                                    >
                                      Download Akad
                                    </Button>
                                  ) : (
                                    <Button
                                      color="blue"
                                      size="mini"
                                      href={wbuFile}
                                      download={wbuFile}
                                      target="_blank"
                                    >
                                      Download Akad
                                    </Button>
                                  )}
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  {dataHUjroh.Musyarakah ? (
                                    <Button
                                      href={dataHUjroh.Musyarakah.file_name}
                                      className="btnAkad"
                                      target="_blank"
                                      id="fonts"
                                      color="blue"
                                      size="mini"
                                    >
                                      Download Akad dengan Pemodal
                                    </Button>
                                  ) : (
                                    <Button
                                      id="fonts"
                                      color="blue"
                                      size="mini"
                                      onClick={openMdlTTD}
                                      disabled
                                    >
                                      Download Akad dengan Pemodal
                                    </Button>
                                  )}
                                  <br />
                                  <div
                                    className="nPic"
                                    style={{ marginTop: "5px" }}
                                  >
                                    <div className="ui middle aligned left aligned grid container">
                                      {dataHUjroh.ujroh_status > 0 ? (
                                        <>
                                          <div className="ui fluid segment grContainer">
                                            <input
                                              type="file"
                                              disabled
                                              name="wbu"
                                              className="inputfile"
                                              id="embedpollfileinputwbu"
                                              onChange={(event) => {
                                                this.onFileChange(event, 82);
                                              }}
                                            />
                                            <label
                                              htmlFor="embedpollfileinputwbu"
                                              className="ui mini blue right floated button"
                                              style={{ opacity: "0.5" }}
                                            >
                                              <i className="ui upload icon"></i>
                                              {wbu}
                                            </label>
                                          </div>
                                          <div
                                            className="ui fluid segment grContainer"
                                            style={{ marginLeft: "5px" }}
                                          >
                                            <Button
                                              id="fonts"
                                              disabled
                                              color="blue"
                                              className="uploadWbu"
                                              size="mini"
                                              type="button"
                                              onClick={handleUplWbuFile}
                                            >
                                              Upload
                                            </Button>
                                          </div>{" "}
                                        </>
                                      ) : (
                                        <>
                                          <div className="ui fluid segment grContainer">
                                            <input
                                              type="file"
                                              name="wbu"
                                              className="inputfile"
                                              id="embedpollfileinputwbu"
                                              onChange={(event) => {
                                                this.onFileChange(event, 82);
                                              }}
                                            />
                                            <label
                                              htmlFor="embedpollfileinputwbu"
                                              className="ui mini blue right floated button"
                                            >
                                              <i className="ui upload icon"></i>
                                              {wbu}
                                            </label>
                                          </div>
                                          <div
                                            className="ui fluid segment grContainer"
                                            style={{ marginLeft: "5px" }}
                                          >
                                            <Button
                                              id="fonts"
                                              color="blue"
                                              className="uploadWbu"
                                              size="mini"
                                              type="button"
                                              onClick={handleUplWbuFile}
                                            >
                                              Upload
                                            </Button>
                                          </div>{" "}
                                        </>
                                      )}

                                      {dataHUjroh.Musyarakah ? (
                                        <>
                                          <div
                                            className="ui fluid segment grContainer"
                                            style={{ marginLeft: "5px" }}
                                          >
                                            <input
                                              type="file"
                                              name="musyarokah"
                                              className="inputfile"
                                              id="embedpollfileinputmusy"
                                              onChange={(event) => {
                                                this.onFileChange(event, 81);
                                              }}
                                            />
                                            <label
                                              htmlFor="embedpollfileinputmusy"
                                              className="ui mini blue right floated button"
                                            >
                                              <i className="ui upload icon"></i>
                                              {musyarokah}
                                            </label>
                                          </div>
                                          <div
                                            className="ui fluid segment grContainer"
                                            style={{ marginLeft: "5px" }}
                                          >
                                            <Button
                                              id="fonts"
                                              color="blue"
                                              className="uploadMusyarokah"
                                              size="mini"
                                              type="button"
                                              onClick={handleUplMusyarakah}
                                            >
                                              Upload
                                            </Button>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div
                                            className="ui fluid segment grContainer"
                                            style={{ marginLeft: "5px" }}
                                          >
                                            <input
                                              disabled
                                              type="file"
                                              name="musyarokah"
                                              className="inputfile"
                                              id="embedpollfileinputmusy"
                                              onChange={(event) => {
                                                this.onFileChange(event, 81);
                                              }}
                                            />
                                            <label
                                              htmlFor="embedpollfileinputmusy"
                                              className="ui mini blue right floated button"
                                              style={{ opacity: "0.5" }}
                                            >
                                              <i className="ui upload icon"></i>
                                              {musyarokah}
                                            </label>
                                          </div>
                                          <div
                                            className="ui fluid segment grContainer"
                                            style={{ marginLeft: "5px" }}
                                          >
                                            <Button
                                              id="fonts"
                                              color="blue"
                                              className="uploadMusyarokah"
                                              size="mini"
                                              type="button"
                                              disabled
                                              onClick={handleUplSubmitTermin1}
                                            >
                                              Upload
                                            </Button>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <br />
                              <br />
                              <div className="divDetBayarUjroh">
                                <div className="judulDetPengelola">
                                  <span>Pembayaran Platform Fee</span>
                                </div>
                                {dataHUjroh.status_upload == "Sudah" ? (
                                  <>
                                    <Row>
                                      <Col md="12">
                                        <Segment>
                                          <Image
                                            centered
                                            size="medium"
                                            src={waiting}
                                          />
                                          <Header as="h1" className="pWaiting">
                                            Harap menunggu, Tim kami akan
                                            melakukan verifikasi
                                            <br /> pembayaran anda. Terima Kasih
                                          </Header>
                                        </Segment>
                                      </Col>
                                    </Row>
                                  </>
                                ) : (
                                  <>
                                    <div className="divNamaPic">
                                      <div className="jPic">Total</div>
                                      <div className="nPic">
                                        <Row>
                                          <Col md="12">
                                            {this.formatRupiah(
                                              dataHUjroh.nominal
                                            )}
                                          </Col>
                                        </Row>
                                      </div>
                                    </div>
                                    <div className="divNamaPic">
                                      <div className="jPic">
                                        Rekening Tujuan
                                      </div>
                                      <div className="imgRekening col-12">
                                        <div className="namaBank">
                                          A/n Kerjasama.co.id
                                        </div>
                                        <div className="noRek">9084880998</div>
                                      </div>
                                    </div>
                                    <div className="divNamaPic">
                                      <div className="jPic">
                                        Tanggal Transaksi
                                      </div>
                                      <div className="nPic">
                                        <Input
                                          type="date"
                                          name="tglTransaksi_Termin1"
                                          value={tglTransaksi_Termin1}
                                          onChange={handleTextChange}
                                        />
                                      </div>
                                    </div>
                                    <div className="divNamaPic">
                                      <div className="jPic">Bukti Transfer</div>
                                      <div className="nPic">
                                        <div className="ui middle aligned left aligned grid container">
                                          <div className="ui fluid segment grContainer">
                                            <input
                                              type="file"
                                              name="gambartermin1"
                                              className="inputfile"
                                              id="embedpollfileinput"
                                              onChange={(event) => {
                                                this.onFileChange(event, 88);
                                              }}
                                            />
                                            <label
                                              htmlFor="embedpollfileinput"
                                              className="ui small blue right floated button"
                                            >
                                              <i className="ui upload icon"></i>
                                              {gambarTermin1}
                                            </label>
                                          </div>
                                          <div
                                            className="ui fluid segment grContainer"
                                            style={{ marginLeft: "20px" }}
                                          >
                                            <Button
                                              id="fonts"
                                              color="blue"
                                              className="uploadTermin1"
                                              size="small"
                                              type="button"
                                              onClick={handleUplSubmitTermin1}
                                            >
                                              Upload
                                            </Button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                              <br />
                            </Col>
                          </Row>
                        </Container>
                      </Form>
                    ) : null}

                    {hideDivRealisasiPryk == "open" ? (
                      <Form
                        name="formTermin1"
                        onSubmit={(e) => e.preventDefault()}
                        encType="multipart/form-data"
                      >
                        <Container id="fonts">
                          <Row className="justify-content-md-center">
                            <Col md="12">
                              <Breadcrumb className="brPrykBerjalan">
                                <Breadcrumb.Section
                                  link
                                  onClick={clickbackProyekBrjln}
                                >
                                  Status
                                </Breadcrumb.Section>
                                <Breadcrumb.Divider />
                                <Breadcrumb.Section
                                  onClick={(event) => {
                                    this.clickDetailPrykBrjln(
                                      dataDetailPrykBrjln.id_project,
                                      paramBack
                                    );
                                  }}
                                >
                                  Detail
                                </Breadcrumb.Section>
                                <Breadcrumb.Divider />
                                <Breadcrumb.Section active>
                                  Konfirmasi
                                </Breadcrumb.Section>
                              </Breadcrumb>
                              <Message
                                color="yellow"
                                hidden={showMsgRealisasi}
                                className="msgRealisasi"
                              >
                                {msgRealisasi}
                              </Message>
                              <div
                                className="divDetTermin1"
                                style={{ height: "200px" }}
                              >
                                <div className="judulDetTermin1">
                                  <span>Realisasi Hasil Proyek</span>
                                </div>
                                <div className="divNamaPic">
                                  <div className="jPic">Hasil Nilai Proyek</div>
                                  <div className="nPic">
                                    <Input
                                      type="text"
                                      name="hslnilaiProyek"
                                      className="inputhslnilaiproyek"
                                      placeholder="Rp."
                                      value={this.priceHslPryk()}
                                      onBlur={this.handleBlur}
                                      onFocus={this.handleFocus}
                                      onChange={this.handleTextChangeHslPryk}
                                    />
                                  </div>
                                  <br />
                                  Dokumen Pendukung Realisasi Hasil Proyek
                                  <br />
                                  <p className="detailactformat">
                                    {this.formatTanggal(tglUplRealisasi)}
                                  </p>
                                  <div
                                    className="nPic"
                                    style={{ marginTop: "5px" }}
                                  >
                                    <div className="ui middle aligned left aligned grid container">
                                      <div className="ui fluid segment grContainer">
                                        <input
                                          type="file"
                                          name="realisasihslpryk"
                                          className="inputfile"
                                          id="embedpollfilerealisasihslpryk"
                                          onChange={(event) => {
                                            this.onFileChange(event, 17);
                                          }}
                                        />
                                        <label
                                          htmlFor="embedpollfilerealisasihslpryk"
                                          className="ui mini blue right floated button embedpollfilerealisasihslpryk"
                                        >
                                          <i className="ui upload icon"></i>
                                          {realisasihslpryk}
                                        </label>
                                      </div>
                                      <div
                                        className="ui fluid segment grContainer"
                                        style={{ marginLeft: "5px" }}
                                      >
                                        <Button
                                          id="fonts"
                                          color="blue"
                                          className="uploadRealisasi"
                                          size="mini"
                                          type="button"
                                          onClick={handleUplRealisasi}
                                        >
                                          Upload
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                  <p className="detailactformat">
                                    File harus berupa format zip
                                  </p>
                                </div>
                              </div>
                              <br />
                              <br />
                              <div className="divDetBayarUjroh">
                                <div className="judulDetPengelola">
                                  <span>Pembayaran Hasil Proyek</span>
                                </div>
                                {dataDetailPrykBrjln.bill_payment != 0 ? (
                                  <>
                                    <div className="divNamaPic">
                                      <div className="nPic">
                                        {dataDetailPrykBrjln.bill_payment.map(
                                          (itm, ind) => {
                                            return (
                                              <Row
                                                style={{ marginBottom: "10px" }}
                                              >
                                                <Col md="3">
                                                  <div className="jPic">
                                                    Termin Ke - {itm.termin}
                                                  </div>
                                                  <div className="nPic">
                                                    <Row>
                                                      <Col md="12">
                                                        <Input
                                                          type="text"
                                                          name={
                                                            "nominalBillPayment" +
                                                            itm.termin
                                                          }
                                                          className={
                                                            "nominalBillPayment" +
                                                            itm.termin
                                                          }
                                                          value={this.formatRupiah(
                                                            itm.nominal
                                                          )}
                                                        />
                                                        <Input
                                                          type="hidden"
                                                          name={
                                                            "nominalTermin" +
                                                            itm.termin
                                                          }
                                                          value={itm.nominal}
                                                        />
                                                      </Col>
                                                    </Row>
                                                  </div>
                                                </Col>
                                                <Col md="2">
                                                  <div className="jPic">
                                                    Tanggal Jatuh Tempo
                                                  </div>
                                                  <div className="nPic">
                                                    <Row>
                                                      <Col md="12">
                                                        <p
                                                          className={
                                                            "dueDateTermin" +
                                                            itm.termin
                                                          }
                                                        >
                                                          {this.formatTanggal(
                                                            itm.due_date
                                                          )}
                                                        </p>
                                                      </Col>
                                                    </Row>
                                                  </div>
                                                </Col>
                                                <Col md="3">
                                                  <div className="jPic">
                                                    Tanggal Transaksi
                                                  </div>
                                                  <div className="nPic">
                                                    <Row>
                                                      <Col md="12">
                                                        <Input
                                                          type="date"
                                                          className={
                                                            "tglBillPaymentTermin" +
                                                            itm.termin
                                                          }
                                                          name={
                                                            "tglBillPaymentTermin" +
                                                            itm.termin
                                                          }
                                                        />
                                                      </Col>
                                                    </Row>
                                                  </div>
                                                </Col>
                                                <Col md="4">
                                                  <div
                                                    className={
                                                      "jPic jdlTerminHslPryk" +
                                                      itm.termin
                                                    }
                                                  >
                                                    Bukti Transfer
                                                  </div>
                                                  <div className="nPic">
                                                    <Row>
                                                      <Col md="12">
                                                        <div className="nPic">
                                                          <div
                                                            className={
                                                              "ui middle aligned left aligned grid container divTerminHslPryk" +
                                                              itm.termin
                                                            }
                                                          >
                                                            <div className="ui fluid segment grContainer">
                                                              <input
                                                                type="file"
                                                                name={
                                                                  "fileBillPayTermin" +
                                                                  itm.termin
                                                                }
                                                                className={
                                                                  "inputfile fileBillPayTermin" +
                                                                  itm.termin
                                                                }
                                                                id={
                                                                  "embedpollfileinput" +
                                                                  itm.termin
                                                                }
                                                                onChange={(
                                                                  event
                                                                ) => {
                                                                  this.onFileChange(
                                                                    event,
                                                                    90,
                                                                    itm.termin
                                                                  );
                                                                }}
                                                              />
                                                              <label
                                                                htmlFor={
                                                                  "embedpollfileinput" +
                                                                  itm.termin
                                                                }
                                                                className="ui small blue right floated button"
                                                                id={
                                                                  "fileBillPayTermin" +
                                                                  itm.termin
                                                                }
                                                              ></label>
                                                            </div>
                                                            <div
                                                              className="ui fluid segment grContainer"
                                                              style={{
                                                                marginLeft:
                                                                  "3px",
                                                              }}
                                                            >
                                                              <Button
                                                                id="fonts"
                                                                color="blue"
                                                                className={
                                                                  "uploadBillTermin" +
                                                                  itm.termin
                                                                }
                                                                size="small"
                                                                type="button"
                                                                onClick={(
                                                                  event
                                                                ) => {
                                                                  handleUplBillPayment(
                                                                    itm.termin,
                                                                    itm.bill_id
                                                                  );
                                                                }}
                                                              >
                                                                Upload
                                                              </Button>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </Col>
                                                    </Row>
                                                  </div>
                                                </Col>
                                              </Row>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="divNamaPic">
                                      <div className="jPic">Total</div>
                                      <div className="nPic">
                                        <Row>
                                          <Col md="12">-</Col>
                                        </Row>
                                      </div>
                                    </div>
                                  </>
                                )}
                                <div
                                  className="divNamaPic"
                                  style={{ opacity: "0.5" }}
                                >
                                  <div className="jPic">Rekening Tujuan</div>
                                  <div className="imgRekening col-12">
                                    <div className="namaBank">
                                      A/n Kerjasama.co.id
                                    </div>
                                    <div className="noRek">9084880998</div>
                                  </div>
                                </div>
                              </div>
                              <br />
                            </Col>
                          </Row>
                        </Container>
                      </Form>
                    ) : null}

                    {hideDivTermin2 == "open" ? (
                      <Form
                        name="formTermin2"
                        onSubmit={(e) => e.preventDefault()}
                        encType="multipart/form-data"
                      >
                        <Container id="fonts">
                          <Row className="justify-content-md-center">
                            <Col md="12">
                              <Breadcrumb className="brPrykBerjalan">
                                <Breadcrumb.Section
                                  link
                                  onClick={clickbackProyekBrjln}
                                >
                                  Status
                                </Breadcrumb.Section>
                                <Breadcrumb.Divider />
                                <Breadcrumb.Section
                                  link
                                  onClick={(event) => {
                                    this.clickDetailPrykBrjln(
                                      dataDetailPrykBrjln.id_project,
                                      paramBack
                                    );
                                  }}
                                >
                                  Detail
                                </Breadcrumb.Section>
                                <Breadcrumb.Divider />
                                <Breadcrumb.Section active>
                                  Pencairan Termin Ke - 2
                                </Breadcrumb.Section>
                              </Breadcrumb>
                              <div className="divDetTermin1">
                                <div className="judulDetTermin1">
                                  <span>Upload Dokumen Penggunaan Dana</span>
                                </div>
                                <div className="divNamaPic">
                                  <div className="jPic termin2">
                                    Upload dokumen laporan realisasi penggunaan
                                    dana.
                                  </div>
                                  <br />
                                  <div className="nPic">
                                    <div className="ui middle aligned left aligned grid container">
                                      <div className="ui fluid segment grContainer">
                                        <input
                                          type="file"
                                          name="pdfgunadana"
                                          className="inputfile"
                                          id="embedpollfileinput"
                                          onChange={(event) => {
                                            this.onFileChange(event, 87);
                                          }}
                                        />
                                        <label
                                          htmlFor="embedpollfileinput"
                                          className="ui small blue right floated button"
                                        >
                                          <i className="ui upload icon"></i>
                                          {gambarPenggunaanDana}
                                        </label>
                                      </div>
                                      <div
                                        className="ui fluid segment grContainer"
                                        style={{ marginLeft: "20px" }}
                                      >
                                        <Button
                                          id="fonts"
                                          className="uploadPenggunaanDana"
                                          color="blue"
                                          size="small"
                                          type="button"
                                          onClick={handleUplSubmit}
                                        >
                                          Upload
                                        </Button>
                                      </div>
                                    </div>
                                    {/* <Button  id="fonts" color='blue' size='mini'>Pilih File</Button> */}
                                  </div>
                                </div>
                              </div>
                              <br />
                              <br />
                            </Col>
                          </Row>
                        </Container>
                      </Form>
                    ) : null}

                    {hideDivStatusPrykSelesai == "open" ? (
                      <>
                        <Container id="fonts" className="divDetailTermin1">
                          <Row className="justify-content-md-center">
                            <Col xs md="12">
                              <Breadcrumb className="brPrykBerjalan">
                                <Breadcrumb.Section
                                  link
                                  onClick={clickbackProyekBrjln}
                                >
                                  Status
                                </Breadcrumb.Section>
                                <Breadcrumb.Divider />
                                <Breadcrumb.Section active>
                                  Detail
                                </Breadcrumb.Section>
                              </Breadcrumb>
                              <Breadcrumb
                                className="brPrykBerjalan"
                                style={{ float: "right" }}
                              >
                                <Breadcrumb.Section
                                  link
                                  onClick={clickbackProyekBrjln}
                                >
                                  {"<< Back"}
                                </Breadcrumb.Section>
                              </Breadcrumb>
                              <div className="divDetPengelola">
                                <div className="judulDetPengelola">
                                  <span>Detail Penerbit</span>
                                </div>
                                <div className="divNamaPic">
                                  <div className="jPic">Nama PIC</div>
                                  <div className="nPic">
                                    {dataDetailStatusPrykSelesai.full_name}
                                  </div>
                                </div>
                                <div className="divNamaPic">
                                  <div className="jPic">Kode Penerbit</div>
                                  <div className="nPic">
                                    ID.{" "}
                                    {dataDetailStatusPrykSelesai.id_pengelola}
                                  </div>
                                </div>
                                {/* <div className="divNamaPic">
                                                                <div className="jPic">Username PrivyID</div>
                                                                <div className="nPic">-</div>
                                                            </div> */}
                                <div className="divNamaPic">
                                  <div className="jPic">Penerbit</div>
                                  <div className="nPic">
                                    {dataDetailStatusPrykSelesai.penerbit}
                                  </div>
                                </div>
                              </div>
                              <br />
                              <br />
                              <div className="divDetProyek">
                                <div className="judulDetPengelola">
                                  <span>Detail Proyek</span>
                                </div>
                                <div className="divNamaPic">
                                  <div className="jPic">Nama Proyek</div>
                                  <div className="nPic">
                                    {dataDetailStatusPrykSelesai.nama_project}
                                  </div>
                                </div>
                                <div className="divNamaPic">
                                  <div className="jPic">Kode Proyek</div>
                                  <div className="nPic">
                                    {dataDetailStatusPrykSelesai.id_project}
                                  </div>
                                </div>
                                <div className="divNamaPic">
                                  <div className="jPic">Kategori Proyek</div>
                                  <div className="nPic">
                                    {dataDetailStatusPrykSelesai.category}
                                  </div>
                                </div>
                                <div className="divNamaPic">
                                  <div className="jPic">Tipe Efek</div>
                                  <div className="nPic">
                                    {dataDetailStatusPrykSelesai.type_efek ==
                                    "EBU"
                                      ? "Efek Bersifat Utang (EBU)"
                                      : ""}
                                    {dataDetailStatusPrykSelesai.type_efek ==
                                    "EBE"
                                      ? "Efek Bersifat Ekuitas (EBE)"
                                      : ""}
                                  </div>
                                </div>
                                <div className="divNamaPic">
                                  <div className="jPic">
                                    Dana Yang Dibutuhkan
                                  </div>
                                  <div className="nPic">
                                    {this.formatRupiah(
                                      dataDetailStatusPrykSelesai.capital_needed
                                    )}
                                  </div>
                                </div>
                              </div>
                              <br />
                              <br />
                              <div className="divDetProyek">
                                <div className="judulDetPengelola">
                                  <span>Detail Pembiayaan</span>
                                </div>
                                <div className="divNamaPic">
                                  <div className="jPic">Status</div>
                                  <div className="nPic">
                                    <Row>
                                      <Col md="8">Crowdfunding Berjalan</Col>
                                      <Col md="4">
                                        <Progress
                                          percent={
                                            dataDetailStatusPrykSelesai.persentase_slot
                                          }
                                          progress
                                          className="progressProjectStatusDet"
                                          style={{ margin: "0 0 0" }}
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                                <div className="divNamaPic">
                                  <div className="jPic">
                                    Dana Yang Sudah Masuk
                                  </div>
                                  <div className="nPic">
                                    {this.formatRupiah(
                                      dataDetailStatusPrykSelesai.dana_masuk
                                    )}
                                  </div>
                                </div>
                                <div className="divNamaPic">
                                  <div className="jPic">Pemodal Yang Masuk</div>
                                  <div className="nPic">
                                    {dataDetailStatusPrykSelesai.total_pemodal}{" "}
                                    Pemodal
                                  </div>
                                </div>
                                <div className="divNamaPic">
                                  <div className="jPic">
                                    Batas Akhir Penggalangan Dana
                                  </div>
                                  <div className="nPic">
                                    {this.formatTanggal(
                                      dataDetailStatusPrykSelesai.date_akhir_val
                                    )}
                                  </div>
                                </div>
                              </div>
                              <br />
                              <br />
                              <div style={{ textAlign: "center" }}>
                                <Button
                                  className={
                                    "btnPencairanTermin1" +
                                    dataDetailStatusPrykSelesai.id_project
                                  }
                                  id="fonts"
                                  color="blue"
                                  size="mini"
                                  onClick={(event) => {
                                    this.clickTermin1(
                                      dataDetailStatusPrykSelesai.id_project,
                                      "dataDetailStatusPryk"
                                    );
                                  }}
                                  style={{ width: "25%" }}
                                >
                                  Syarat Pencairan
                                </Button>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Button
                                  color="blue"
                                  size="mini"
                                  href={
                                    "/Detail/" +
                                    dataDetailStatusPrykSelesai.id_project
                                  }
                                  target="_blank"
                                  style={{ width: "25%" }}
                                >
                                  Lihat Campaign
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </>
                    ) : null}
                  </>
                ) : (
                  <Container
                    style={{ paddingTop: "100px", paddingBottom: "200px" }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        textAlign: "center",
                      }}
                    >
                      Belum ada transaksi yang dapat di tampilkan
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        textAlign: "center",
                        lineHeight: "1.5em",
                      }}
                    >
                      Silahkan menandatangani Perjanjian Penerbit secara
                      digital..
                    </div>
                  </Container>
                )}
              </>
            ) : (
              <Container
                style={{ paddingTop: "100px", paddingBottom: "200px" }}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    textAlign: "center",
                  }}
                >
                  Belum ada transaksi yang dapat di tampilkan
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    textAlign: "center",
                    lineHeight: "1.5em",
                  }}
                >
                  Silahkan lengkapi data profil..
                </div>
              </Container>
            )}
          </Tab>

          <Tab eventKey="bisniscampaign" title="Bisnis Campaign">
            {dataIsComplete == 5 ? (
              <>
                {this.state &&
                this.state.listCampaign &&
                this.state.listCampaign.length > 0 ? (
                  <Container>
                    <Row style={{ paddingTop: "10px" }}>
                      {this.state.listCampaign.map((item) => (
                        <Col
                          xs
                          md="4"
                          key={item.id_project}
                          style={{ paddingTop: "20px", paddingBottom: "20px" }}
                        >
                          <Card className="containerCard DivListProyek">
                            {/* <div className="ui fluid image">
                                                            <a className="ui green right corner label" onClick={() => favoritInsert(item.id_project)}><i aria-hidden="true" className="heart icon"></i></a>
                                                        </div> */}
                            <Card.Content style={{ textAlign: "center" }}>
                              <Card.Header
                                style={{
                                  fontSize: "14px",
                                  padding: "5px",
                                  fontFamily: "Helvetica",
                                }}
                              >
                                {item.nama_project}
                              </Card.Header>
                              <div>
                                <Image
                                  src={item.proyek_data[0].nama_file}
                                  size="medium"
                                  className="imgListProyek"
                                  rounded
                                  style={{ padding: "0px" }}
                                />
                                <label className="lblhari">
                                  {this.timeBetweenDates(
                                    item.date_awal,
                                    item.date_akhir
                                  )}
                                </label>
                              </div>
                              <Card.Meta
                                id="fonts"
                                style={{
                                  textAlign: "center",
                                  color: "#0DB809",
                                  paddingTop: "5px",
                                }}
                              >
                                {this.formatRupiah(item.slot_nominal)} / Slot
                              </Card.Meta>
                            </Card.Content>
                            <Card.Content extra>
                              <div className="ui two buttons">
                                <Header
                                  id="fonts"
                                  as="h6"
                                  color="blue"
                                  className="ProjectDesc"
                                >
                                  Dana dibutuhkan
                                  <Header.Subheader className="ProjectSubDesc">
                                    {this.formatRupiah(item.capital_needed)}
                                  </Header.Subheader>
                                </Header>
                                <Header
                                  id="fonts"
                                  as="h6"
                                  color="blue"
                                  className="ProjectDesc"
                                >
                                  Dividen
                                  <Header.Subheader className="ProjectSubDesc">
                                    {item.est_profit}%
                                  </Header.Subheader>
                                </Header>
                              </div>
                              <div className="ui two buttons">
                                <Header
                                  as="h6"
                                  id="fonts"
                                  color="blue"
                                  className="ProjectDesc"
                                >
                                  Durasi Penawaran
                                  <Header.Subheader className="ProjectSubDesc">
                                    {item.durasi_crowd} Hari
                                  </Header.Subheader>
                                </Header>
                              </div>
                            </Card.Content>
                            <Card.Content extra style={{ textAlign: "center" }}>
                              <Progress
                                percent={item.persentase_slot}
                                progress
                                className="progressProjectDash"
                                style={{ margin: "0 0 0" }}
                              />
                              {/* <Progress 
                                                                percent={item.persentase_slot} 
                                                                progress 
                                                                className="progressProjectDash" 
                                                                style={{paddingLeft: 
                                                                    (item.persentase_slot < 21 && Number.isInteger(item.persentase_slot) ) ? '10px' 
                                                                    : (item.persentase_slot < 21 && !Number.isInteger(item.persentase_slot) ) ? '20px' 
                                                                    : '0px' 
                                                                }} 
                                                            /> */}
                            </Card.Content>
                            <Card.Content extra style={{ textAlign: "center" }}>
                              <Button
                                id="fonts"
                                href={`/Detail/${item.id_project}`}
                                className="col-md-12 btnPilihan"
                                size="medium"
                                style={{
                                  backgroundColor: "rgb(19, 149, 46)",
                                  color: "white",
                                }}
                              >
                                Detail
                              </Button>
                            </Card.Content>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </Container>
                ) : (
                  <Container
                    style={{ paddingTop: "100px", paddingBottom: "200px" }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        textAlign: "center",
                      }}
                    >
                      Belum ada bisnis yang dapat di tampilkan
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        textAlign: "center",
                        lineHeight: "1.5em",
                      }}
                    >
                      Penawaran bisnis belum tersedia..
                    </div>
                  </Container>
                )}
              </>
            ) : (
              <Container
                style={{ paddingTop: "100px", paddingBottom: "200px" }}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    textAlign: "center",
                  }}
                >
                  Belum ada bisnis yang dapat di tampilkan
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    textAlign: "center",
                    lineHeight: "1.5em",
                  }}
                >
                  Silahkan lengkapi data profil..{" "}
                </div>
              </Container>
            )}
          </Tab>

          {/* <Tab eventKey="dompet" title="Saldo" style={{ paddingTop: '0px' }}>
                        {(this.state.hideDiv == 'close') ? (
                            <Container style={{ paddingTop: '20px' }}>
                                <Segment>
                                    <Row className="justify-content-md-center">
                                        <Col xs md="12">
                                            <Header id="fonts" as='h3' color='blue' className='col-md-11'>
                                                Daftar Dompet
                                                {(verified == 1 && verified_advance == 3) ? (
                                                    <div style={{ marginTop: "-20px", textAlign: 'right' }}>
                                                        <div id="fonts" className="ui yellow label"><i>Buatlah PIN Dompet Anda Agar Dompet Dapat Digunakan</i></div>
                                                    </div>) : ''}
                                                <Header.Subheader id="fonts" >Dompet</Header.Subheader>
                                            </Header>
                                            <Header id="fonts" as='h3' color='blue'>
                                                <Image src={dompet} size='mini' />
                                                <Header.Content id="fonts" className='col-md-11'>
                                                    Saldo
                                                    <Header.Subheader id="fonts" >{itemsDompet.saldo}
                                                        {(verified == 1 && verified_advance == 3) ? (
                                                            <div style={{ marginTop: "-30px", textAlign: 'right' }}>
                                                                <Button id="fonts" color='blue' size='mini' onClick={clickdetailDompet}>Lihat Detail</Button>
                                                            </div>

                                                        ) : (
                                                            <div style={{ marginTop: "-30px", textAlign: 'right' }}>
                                                                <Button id="fonts" color='blue' disabled size='mini'>Lihat Detail</Button>
                                                            </div>
                                                        )}
                                                    </Header.Subheader>
                                                </Header.Content>
                                            </Header>
                                        </Col>
                                    </Row>
                                </Segment>
                            </Container>
                        ) : (
                            <Container>
                                <Breadcrumb className="brPrykBerjalan">
                                    <Breadcrumb.Section link onClick={closedetailDompet}>Dompet</Breadcrumb.Section>
                                    <Breadcrumb.Divider />
                                    <Breadcrumb.Section active>Detail</Breadcrumb.Section>
                                </Breadcrumb>
                                <Breadcrumb className="brPrykBerjalan" style={{ float: 'right' }}>
                                    <Breadcrumb.Section link onClick={closedetailDompet}>{'<< Back'}</Breadcrumb.Section>
                                </Breadcrumb>
                                <Segment style={{ marginTop: '0px', padding: '10px 0 0 0' }}>
                                    <DetailDompet hideDiv={this.state.hideDiv} userinfo={JSON.stringify(userinfo)} />
                                </Segment>
                            </Container>
                        )}
                    </Tab> */}
        </Tabs>
      </>
    );
  }
}
